import {
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    OnDestroy,
    PLATFORM_ID,
    QueryList,
    ViewChild,
    ViewChildren,
    TemplateRef,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { Product } from '../../interfaces/product';
import { CarouselComponent, SlidesOutputData } from 'ngx-owl-carousel-o';
import { UntypedFormControl } from '@angular/forms';
import { CartService } from '../../../shared/services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { isPlatformBrowser } from '@angular/common';
import { OwlCarouselOConfig } from 'ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config';
import { PhotoSwipeService } from '../../services/photo-swipe.service';
//import { DirectionService } from '../../services/direction.service';
import { QuickviewService } from '../../services/quickview.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
//import { GoogleAnalyticsService } from '../../../google-analytics.service';
import { CombinatiekortingComponent } from '../combinatiekorting/combinatiekorting.component';

import { CartModalService } from '../../../shared/services/cartmodal.service';
import { Subscription } from 'rxjs';
import { FBCReviewsService } from '../../services/fbc-reviews.service';
import { fbcReviews } from '../../interfaces/fbc-reviews';
import {
    DomSanitizer,
    SafeHtml,
    SafeResourceUrl
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as e from 'express';
import { setLogFunction } from '@google-cloud/firestore';
import { log } from 'console';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CustomGlobalConstants } from '../../../shared/constants/constants';

import { RouterLink } from '@angular/router';
import { LowerCasePipe } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';
import { CurrencyFormatPipe } from '../../pipes/currency-format.pipe';

import { CollapsableTextComponent } from '../collapsable-text/collapsable-text.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';

import { CalculateM2Component } from '../input-calculate-m2/input-calculate-m2.component';
import { FakeSlidesDirective } from '../../directives/fake-slides.directive';
import { M2CalculatorComponent } from '../m2-calculator/m2-calculator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CartModalComponent } from '../cartmodal/cartmodal.component';
import { GTMService } from '../../services/gtm.service';

//import { log } from 'console';

interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}

export type Layout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    selector: 'app-product',
    standalone: true,
    imports: [
        RouterLink,
        LowerCasePipe,
        CollapsableTextComponent,
        CommonModule,
        CurrencyFormatPipe,
        IconComponent,
        CarouselModule,
        FormsModule,
        CalculateM2Component,
        FakeSlidesDirective,
        M2CalculatorComponent,
        ReactiveFormsModule,
        CartModalComponent
    ],

    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductComponent implements OnInit, OnDestroy {
    options = {
        step: 1,
        min: null,
        max: null,
        disabled: false,
        readonly: false,
        m2: null,
        prijsm2: null,
        prijspak: null,
        uitvoeringtype: null
    };
    current = 1;
    ondervloerm2 = 0;

    private dataProduct: Product;
    private dataLayout: Layout = 'standard';
    modalRef: BsModalRef;

    isCollapsed = false;
    showGallery = true;
    showGalleryTimeout: number;
    showingQuickview = false;
    //heatfoilkorting = 0;
    //timbersoundkorting = 0;
    //timbermatekorting = 0;

    productTimbermateExel: Product;
    productTimberSound: Product;
    productHeatfoil: Product;

    aantalpak = 0;
    aantalondervloer = 1;
    obj: any;
    blackfridayactie: boolean;
    iDealKortingPercentage: number;

    // fbcCount: string;
    // fbcTotal: string;
    // fbcScore: string;

    @ViewChild('featuredCarousel', { read: CarouselComponent })
    featuredCarousel: CarouselComponent;
    @ViewChild('thumbnailsCarousel', { read: CarouselComponent })
    thumbnailsCarousel: CarouselComponent;
    @ViewChildren('imageElement', { read: ElementRef })
    imageElements: QueryList<ElementRef>;

    @Input() changeValue: string;

    @Input() set layout(value: Layout) {
        this.dataLayout = value;

        if (isPlatformBrowser(this.platformId)) {
            // this dirty hack is needed to re-initialize the gallery after changing the layout
            clearTimeout(this.showGalleryTimeout);
            this.showGallery = false;
            this.showGalleryTimeout = window.setTimeout(() => {
                this.showGallery = true;
            }, 0);
        }
    }
    get layout(): Layout {
        return this.dataLayout;
    }

    @Input() set product(value: Product) {
        this.dataProduct = value;
        this.images = value
            ? this.dataProduct.images.map((url, index) => {
                  return {
                      id: index.toString(),
                      url,
                      active: index === 0
                  };
              })
            : [];
    }
    get product(): Product {
        //   console.log(JSON.stringify(this.dataProduct));
        return this.dataProduct;
    }

    images: ProductImage[] = [];

    carouselOptions: Partial<OwlCarouselOConfig> = {
        loop: true,
        lazyLoad: false,
        autoplay: true,

        responsive: {
            0: { items: 1 }
        },
        rtl: false
        //rtl: this.direction.isRTL()
    };

    thumbnailsCarouselOptions: Partial<OwlCarouselOConfig> = {
        loop: true,
        dots: false,
        lazyLoad: false,
        autoplay: false,
        margin: 10,
        items: 5,
        responsive: {
            480: { items: 5 },
            380: { items: 4 },
            0: { items: 3 }
        },
        rtl: false
        //rtl: this.direction.isRTL()
    };

    quantity: UntypedFormControl = new UntypedFormControl(1);

    selectedOndervloer = 0;

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;

    onCompareList = false;
    anyOnCompareList = false;
    showMore = false;

    test: string;

    comkortingpercentage = 22;
    // comkortinghoog = 410.2;
    // comkortinglaag = 390.32;

    //selectedOption: string;
    combinatieondervloer = 0;

    receivedChildMessage: number;
    messageToSendP = 0;

    onTouched = () => {};
    @ViewChild('aantalpakken', { static: true }) aantalpakken: ElementRef; //!!

    @Output() valueChange = new EventEmitter();

    @Output() messageToEmit = new EventEmitter<string>();

    get value(): '' | number {
        return;
    }

    set value(value: '' | number) {
        this.writeValue(value);
    }

    message: string;
    subscription: Subscription;
    fbcreviews: fbcReviews;
    //fbcreviews: object;
    jsonLD: SafeHtml;
    lastViewedArray = [];

    readMore = false;

    longText: string;

    verschil: number;
    counter = 1;
    sanitizedHtml: SafeHtml;
    sanitizedResourceUrl: SafeResourceUrl;
    typedetail: any;

    // redfloor pvc

    // timbersound laminaat

    constructor(
        //   public googleAnalyticsService: GoogleAnalyticsService,
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        private photoSwipe: PhotoSwipeService,
        //private direction: DirectionService,
        public quickview: QuickviewService,
        public cartmodal: CartModalService,
        private modalService: BsModalService,
        //private data: CartModalService,
        private wishlistmodalserv: WishlistService,
        private httpService: FBCReviewsService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private httpClient: HttpClient,
        private route: ActivatedRoute, //private analytics: AngularFireAnalytics
        private cdr: ChangeDetectorRef,
        private _dataLayerService: GTMService
    ) {
        this.blackfridayactie = CustomGlobalConstants.BlackFridayActie;
        this.iDealKortingPercentage = CustomGlobalConstants.iDealKorting;
    }

    // async ngOnInit(): Promise<void> {
    ngOnInit(): void {
        // this.httpService
        //     .getFBCReviews()
        //     .subscribe((res) => (this.fbcreviews = res));

        this.route.params.subscribe((params) => {
            this.httpClient
                .get(
                    'https://api.voordeelvloeren.nl/zelfde-serie.php?id=' +
                        params['productSlug'],
                    {
                        responseType: 'text'
                    }
                )
                .subscribe((response) => {
                    this.obj = JSON.parse(response);
                    if (this.product.categorieid == 2) {
                        this.counter = 2;
                    }
                    this.verschil =
                        Math.round(
                            (this.product.combinatieondervloerpaknormaal[
                                this.counter
                            ] -
                                this.product.combinatieondervloerpakactie[
                                    this.counter
                                ]) *
                                100
                        ) / 100;

                    this.longText =
                        '<br><br>Je vindt de combinatieprijs bij elke laminaat en PVC vloer. Lees of scrol even verder bij elke vloer en selecteer de beste ondervloer. De prijs van de ondervloeren zijn dan extra verlaagd en kun je precies zien wat je extra kunt besparen. <br><br><h2>Reken voorbeeld</h2>    Stel je bestelt 20 pakken ' +
                        this.product.name +
                        ' in combinatie met een ';

                    if (this.product.categorieid == 2) {
                        this.longText += 'Redfloor';
                    } else {
                        this.longText += 'TimberSound';
                    }

                    this.longText +=
                        ' ondervloer dan is de prijs van de ondervloer niet &euro; ' +
                        (
                            Math.round(
                                this.product.combinatieondervloerpaknormaal[
                                    this.counter
                                ] * 100
                            ) / 100
                        ).toFixed(2) +
                        ' per rol maar slechts &euro; ' +
                        (
                            Math.round(
                                this.product.combinatieondervloerpakactie[
                                    this.counter
                                ] * 100
                            ) / 100
                        ).toFixed(2) +
                        ' per rol. Een voordeel van € ' +
                        this.verschil.toFixed(2) +
                        ' per rol en € ' +
                        (
                            Math.ceil(
                                (this.product.m2 * 20) /
                                    this.product.combinatieondervloerm2[
                                        this.counter
                                    ]
                            ) *
                            1 *
                            this.verschil
                        ).toFixed(2) +
                        ' extra korting bij afname van ' +
                        Math.ceil(
                            (this.product.m2 * 20) /
                                this.product.combinatieondervloerm2[
                                    this.counter
                                ]
                        ) +
                        ' rollen.<br><br>Je hebt dan de beste geluiddempende ondervloer die ook geschikt is voor vloerverwarming. De warmteweerstand is slechts ' +
                        (
                            this.product.combinatieondervloerww[this.counter] *
                                1 +
                            this.product.warmteweerstand * 1
                        ).toFixed(3) +
                        ' m2 K / W voor laminaatvloer + TimberSound ondervloer.Bij iedere laminaat of PVC vloer vind je de combinatie korting met ondervloer naar keuze voor elke kamer in huis of appartement. ';

                    if (this.product.typedetail !== ' ') {
                        this.typedetail =
                            this.sanitizer.bypassSecurityTrustHtml(
                                this.product.typedetail
                            ) as string;
                    } else {
                        this.typedetail = '';
                    }
                });
        });

        this.subscription = this.cartmodal.currentMessageCM.subscribe(
            (message) => (this.message = message)
        );

        if (this.layout !== 'quickview' && isPlatformBrowser(this.platformId)) {
            this.photoSwipe.load().subscribe();
        }

        this.onCompareList = this.compare.onCompareList(this.product);
        this.anyOnCompareList = this.compare.anyOnCompareList();

        this.httpService
            .getFBCReviews()

            .subscribe((res) => {
                this.fbcreviews = res;

                const json = {
                    '@context': 'http://schema.org/',
                    '@type': 'Product',
                    name: this.product.name,
                    image: this.product.images[0],
                    url:
                        'https://www.voordeelvloeren.nl/product/' +
                        this.product.id +
                        '/' +
                        this.product.slug,
                    description: this.product.metadescription,
                    sku: this.product.ean,
                    id:
                        'https://www.voordeelvloeren.nl/product/' +
                        this.product.id +
                        '/' +
                        this.product.slug,
                    brand: {
                        '@type': 'Brand',
                        name: this.product.merknaam
                    },

                    offers: {
                        '@type': 'Offer',
                        priceCurrency: 'EUR',
                        price: this.product.prijsm2,
                        url:
                            'https://www.voordeelvloeren.nl/product/' +
                            this.product.id +
                            '/' +
                            this.product.slug,
                        itemCondition: 'https://schema.org/NewCondition',
                        availability: 'http://schema.org/InStock'
                    },
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: this.fbcreviews['score'].toString(),
                        reviewCount: this.fbcreviews['count'].toString(),
                        bestRating: '10',
                        worstRating: '1'
                    }
                };

                this.jsonLD = this.getSafeHTML(json);
            });

        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('lastViewed')) {
                this.lastViewedArray = JSON.parse(
                    localStorage.getItem('lastViewed')
                );
            }
        }

        if (this.lastViewedArray.length > 0) {
            if (this.lastViewedArray.length == 10) {
                this.lastViewedArray.pop();
            }

            this.lastViewedArray.forEach((element, index) => {
                if (element == this.product.id)
                    this.lastViewedArray.splice(index, 1);
            });

            this.lastViewedArray.unshift(this.product.id);

            if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem(
                    'lastViewed',
                    JSON.stringify(this.lastViewedArray)
                );
            }
        } else {
            this.lastViewedArray = [this.product.id];
            if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem(
                    'lastViewed',
                    JSON.stringify(this.lastViewedArray)
                );
            }
        }

        if (isPlatformBrowser(this.platformId)) {
            this._dataLayerService.logEvent('view_item', this.product.prijsm2, [
                {
                    item_id: 'vdvl-' + this.product.id,
                    item_name: this.product.name,
                    currency: 'EUR',
                    item_brand: this.product.merknaam,
                    item_category: this.product.categorienaam,
                    price: this.product.prijsm2,
                    quantity: 1
                }
            ]);
        }

        // this.analytics.logEvent('view_item', {
        //     currency: 'EUR',
        //     value: this.product.prijsm2,
        //     items: [
        //         {
        //             item_id: 'vdvl-' + this.product.id,
        //             item_name: this.product.name,
        //             currency: 'EUR',
        //             item_brand: this.product.merknaam,
        //             item_category: this.product.categorienaam,
        //             price: this.product.prijsm2,
        //             quantity: 1
        //         }
        //     ]
        // });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    callType(value: number): void {
        //    this.test = this.quantity;

        // console.log(
        //     'X!X' +
        //         this.product.combinatieondervloerprijzen[value].values[0].name
        // );
        // this.comkortingpercentage =
        //     parseFloat(
        //         this.product.combinatieondervloerprijzen[value].values[0].name
        //     ) -
        //     parseFloat(
        //         this.product.combinatieondervloerprijzen[value].values[0].slug
        //     );

        // this.comkortingpercentage =
        //     this.comkortingpercentage /
        //     (parseFloat(
        //         this.product.combinatieondervloerprijzen[value].values[0].name
        //     ) /
        //         100);

        // this.comkortingpercentage = Math.round(this.comkortingpercentage);

        this.aantalondervloer = Math.ceil(
            (this.aantalpak * this.product.m2) /
                this.product.combinatieondervloerm2[value]
        );

        // this.comkortinghoog =
        //     this.aantalpak * this.product.prijspak +
        //     this.aantalondervloer *
        //         this.product.combinatieondervloerpaknormaal[value];

        // this.comkortinglaag =
        //     this.aantalpak * this.product.prijspak +
        //     this.aantalondervloer *
        //         this.product.combinatieondervloerpakactie[value];

        // console.log('XXXXX' + this.comkortinghoog);
    }

    setActiveImage(image: ProductImage): void {
        this.images.forEach(
            (eachImage) => (eachImage.active = eachImage === image)
        );
    }

    combinatielinks(value: number): void {
        // if (value == 0) {
        //     return;
        // }
        // console.log(
        //     'Combinatie: ' +
        //         this.product.combinatieondervloernaam.length +
        //         '-' +
        //         value
        // );
        if (value == 0) {
            this.selectedOndervloer =
                this.product.combinatieondervloernaam.length - 1;
            this.callType(this.product.combinatieondervloernaam.length - 1);
            return;
        }
        this.selectedOndervloer = value * 1 - 1;
        this.callType(value * 1 - 1);
        //this.selectedcombinatieondervloer.get('mijnondervloer').patchValue(2);
    }

    combinatierechts(value: number): void {
        // if (value == 18) {
        //     return;
        // }

        if (value == this.product.combinatieondervloernaam.length - 1) {
            this.selectedOndervloer = 0;
            this.callType(0);
            return;
        }
        this.selectedOndervloer = value * 1 + 1;
        this.callType(value * 1 + 1);
    }

    featuredCarouselTranslated(event: SlidesOutputData): void {
        if (event.slides.length) {
            const activeImageId = event.slides[0].id;

            this.images.forEach(
                (eachImage) =>
                    (eachImage.active = eachImage.id === activeImageId)
            );

            if (
                !this.thumbnailsCarousel.slidesData.find(
                    (slide) => slide.id === event.slides[0].id && slide.isActive
                )
            ) {
                this.thumbnailsCarousel.to(event.slides[0].id);
            }
        }
    }

    addToCart(): void {
        // if (this.showingQuickview) {
        //     return;
        // }

        // this.showingQuickview = true;
        // this.quickview.show(this.product).subscribe({
        //     complete: () => {
        //         this.showingQuickview = false;
        //         this.cd.markForCheck();
        //     }
        // });

        ///////////
        if (!this.addingToCart && this.product && this.aantalpak > 0) {
            if (!this.addingToCart && this.product && this.quantity.value > 0) {
                if (isPlatformBrowser(this.platformId)) {
                    this._dataLayerService.logEvent(
                        'add_to_cart',
                        this.product.prijspak * this.aantalpak,
                        [
                            {
                                item_id: 'vdvl-' + this.product.id,
                                item_name: this.product.name,
                                currency: 'EUR',
                                item_brand: this.product.merknaam,
                                item_category: this.product.categorienaam,
                                price: this.product.prijsm2,
                                quantity: this.aantalpak
                            }
                        ]
                    );
                }

                // this.analytics.logEvent('add_to_cart', {
                //     currency: 'EUR',
                //     value: this.product.prijspak * this.aantalpak,
                //     items: [
                //         {
                //             item_id: 'vdvl-' + this.product.id,
                //             item_name: this.product.name,
                //             currency: 'EUR',
                //             item_brand: this.product.merknaam,
                //             item_category: this.product.categorienaam,
                //             price: this.product.prijspak,
                //             quantity: this.aantalpak
                //         }
                //     ]
                // });

                this.addingToCart = true;

                // KLIKMAKERS OPLOSSEN
                // this.googleAnalyticsService.eventEmitter(
                //     'sale',
                //     'product',
                //     'add-to-cart',
                //     'click',
                //     1
                // );

                // stuur data naar (addedTo)CartModal
                this.cartmodal.toModalCart(this.aantalpak + '-0-0');

                this.cart
                    .add(this.product, this.aantalpak, 0, 0, false)
                    .subscribe({ complete: () => (this.addingToCart = false) });
            }
        }
    }

    displaySum(sum, ondervloerm2): void {
        this.aantalpak = parseInt(sum);

        this.current = parseInt(sum);

        this.ondervloerm2 = parseInt(ondervloerm2);

        //        console.log('BBBB' + this.ondervloerm2);

        // console.warn('!!!!!!!!!!!!!!!!!!!!!!!!!!!');

        if (this.ondervloerm2) {
            this.aantalondervloer = Math.ceil(
                (this.aantalpak * this.product.m2) / this.ondervloerm2
            );
        }
        //     this.comkortinghoog =
        //         sum * this.product.prijspak +
        //         this.aantalondervloer *
        //             this.product.combinatieondervloerpaknormaal[
        //                 this.combinatieondervloer
        //             ];

        //     this.comkortinglaag =
        //         sum * this.product.prijspak +
        //         this.aantalondervloer *
        //             this.product.combinatieondervloerpakactie[
        //                 this.combinatieondervloer
        //             ];
        //
    }

    addToCartCombinatieKorting(type): void {
        //        console.log('belangrijk' + JSON.stringify(this.quantity));

        /*
    this.productTimbermateExel =

    {
        id: 275,
        name: 'Timbermate Exel',
        price: parseFloat(this.product.attributes[8].values[0].slug) * 15,
        images: ["https://firebasestorage.googleapis.com/v0/b/voordeelvloeren-c005d.appspot.com/o/ondervloer-timbermate-exel_800x800.jpg?alt=media&token=9d038c5d-75cb-400d-8fd1-cee1417a19d6"],

        categorieid: 0,
        categorienaam: '',
        slug: '',
        slugmerk: '',
        slugserie: '',
        merknaam: '',
        serienaam: '',
        typenaam: '',
        pricetape: 0,
        priceplint: 0,
        m2: 0,
        gratisvloer: 0,
        compareAtPrice: 0,
        metadescription: '',
        badges: [],
        rating: 0,
        reviews: 0,
        availability: '',
        matlook: 0,
        garantie: '',
        typedetail: '',
        ean: '',
        brand: null,
        categories: null,
        attributes: null,
        customFields: null


    };

    console.log('asd2');

    this.productTimberSound =

    {
        id: 279,
        name: 'TimberSound m2',
        price: parseFloat(this.product.attributes[9].values[0].slug) * 10,
        images: ["https://firebasestorage.googleapis.com/v0/b/voordeelvloeren-c005d.appspot.com/o/ondervloer-timbersound-1_800x800.jpg?alt=media&token=9d038c5d-75cb-400d-8fd1-cee1417a19d6"],
        categorieid: 0,
        categorienaam: '',
        slug: '',
        slugmerk: '',
        slugserie: '',
        merknaam: '',
        serienaam: '',
        typenaam: '',
        pricetape: 0,
        priceplint: 0,
        m2: 0,
        gratisvloer: 0,
        compareAtPrice: 0,
        metadescription: '',
        badges: [],
        rating: 0,
        reviews: 0,
        availability: '',
        matlook: 0,
        garantie: '',
        typedetail: '',
        ean: '',
        brand: null,
        categories: null,
        attributes: null,
        customFields: null
    };

    console.log('asd3');

    this.productHeatfoil =

    {
        id: 269,
        name: 'Heatfoil 15m2 (10Db)',
        price: parseFloat(this.product.attributes[8].values[0].slug) * 15,
        images: ["https://firebasestorage.googleapis.com/v0/b/voordeelvloeren-c005d.appspot.com/o/ondervloer-.heatfoil_800x800.jpg?alt=media&token=9d038c5d-75cb-400d-8fd1-cee1417a19d6"],
        categorieid: 0,
        categorienaam: '',
        slug: '',
        slugmerk: '',
        slugserie: '',
        merknaam: '',
        serienaam: '',
        typenaam: '',
        pricetape: 0,
        priceplint: 0,
        m2: 0,
        gratisvloer: 0,
        compareAtPrice: 0,
        metadescription: '',
        badges: [],
        rating: 0,
        reviews: 0,
        availability: '',
        matlook: 0,
        garantie: '',
        typedetail: '',
        ean: '',
        brand: null,
        categories: null,
        attributes: null,
        customFields: null
    };

*/

        //        console.log(this.productTimbermateExel);

        // console.log(this.product);
        // console.log('XYX');

        // console.log(
        //     Math.ceil(
        //         (this.aantalpak * this.product.m2) /
        //             this.product.combinatieondervloerm2[type]
        //     )
        // );

        if (!this.addingToCart && this.product && this.aantalpak > 0) {
            this.addingToCart = true;

            // console.log('ASDASD' + this.product.combinatieondervloerm2[type]);

            // console.log(
            //     Math.ceil(
            //         (this.aantalpak * this.product.m2) /
            //             this.product.combinatieondervloerm2[type]
            //     )
            // );

            // stuur data naar (addedTo)CartModal
            this.cartmodal.toModalCart(
                this.aantalpak +
                    '-' +
                    Math.ceil(
                        (this.aantalpak * this.product.m2) /
                            this.product.combinatieondervloerm2[type]
                    ) +
                    '-' +
                    type
            );

            this.cart
                .add(
                    this.product,
                    this.aantalpak,
                    Math.ceil(
                        (this.aantalpak * this.product.m2) /
                            this.product.combinatieondervloerm2[type]
                    ),
                    type,
                    false
                )
                .subscribe({ complete: () => (this.addingToCart = false) });
            //     if (type == "timbermate") { this.cart.add(this.productTimbermateExel, Math.ceil(this.product.m2*this.aantalpak/15)).subscribe({ complete: () => this.addingToCart = false }); }
            //     if (type == "timbersound") { this.cart.add(this.productTimberSound, Math.ceil(this.product.m2*this.aantalpak/10)).subscribe({ complete: () => this.addingToCart = false }); }
            //     if (type == "heatfoil") { this.cart.add(this.productHeatfoil, Math.ceil(this.product.m2*this.aantalpak/15)).subscribe({ complete: () => this.addingToCart = false }); }
        }
    }

    addToWishlist(): void {
        if (!this.addingToWishlist && this.product) {
            this.addingToWishlist = true;
            this.cartmodal.toModalCart('1-0-0');

            this.wishlist
                .add(this.product)
                .subscribe({ complete: () => (this.addingToWishlist = false) });
        }
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare
                .add(this.product)
                .subscribe({ complete: () => (this.addingToCompare = false) });
        }
    }

    toggleCompare(): void {
        //  this.onCompareList = this.compare.onCompareList(this.product);
        //      console.log("Z1");
        // console.log(this.addingToCompare);
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            if (this.compare.onCompareList(this.product) === false) {
                this.compare.add(this.product).subscribe({
                    complete: () => (this.addingToCompare = false)
                });
                this.addingToCompare = true;
            } else {
                this.compare.remove(this.product).subscribe({
                    complete: () => (this.addingToCompare = false)
                });
                this.addingToCompare = false;
            }
        }
    }

    getIndexDependOnDir(index) {
        // we need to invert index id direction === 'rtl' because photoswipe do not support rtl
        // if (this.direction.isRTL()) {
        //     return this.images.length - 1 - index;
        // }

        return index;
    }

    openPhotoSwipe(event: MouseEvent, image: ProductImage): void {
        if (this.layout !== 'quickview') {
            event.preventDefault();

            const images = this.images.map((eachImage) => {
                return {
                    src: eachImage.url,
                    msrc: eachImage.url,
                    w: 700,
                    h: 700
                };
            });

            // if (this.direction.isRTL()) {
            //     images.reverse();
            // }

            const options = {
                getThumbBoundsFn: (index) => {
                    const imageElements = this.imageElements.toArray();
                    const dirDependentIndex = this.getIndexDependOnDir(index);

                    if (!imageElements[dirDependentIndex]) {
                        return null;
                    }

                    const imageElement =
                        imageElements[dirDependentIndex].nativeElement;
                    const pageYScroll =
                        window.pageYOffset ||
                        document.documentElement.scrollTop;
                    const rect = imageElement.getBoundingClientRect();

                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    };
                },
                index: this.getIndexDependOnDir(this.images.indexOf(image)),
                bgOpacity: 0.9,
                history: false
            };

            this.photoSwipe.open(images, options).subscribe((galleryRef) => {
                galleryRef.listen('beforeChange', () => {
                    this.featuredCarousel.to(
                        this.images[
                            this.getIndexDependOnDir(
                                galleryRef.getCurrentIndex()
                            )
                        ].id
                    );
                });
            });
        }
    }

    openModalWithClass(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-lg' })
        );
    }

    getMessage(message: number) {
        this.receivedChildMessage = message;
        //this.product.m2 = message;
        this.sendToChild(message);
        this.current = message;
    }

    sendToChild(message: number) {
        this.messageToSendP = message;
    }

    ////////////////////

    // showQuickview(): void {
    //     if (this.showingQuickview) {
    //         return;
    //     }

    //     this.showingQuickview = true;
    //     this.quickview.show(this.product).subscribe({
    //         complete: () => {
    //             this.showingQuickview = false;
    //             this.cd.markForCheck();
    //         }
    //     });
    // }

    ///////////////////

    changeValues(): void {
        this.valueChange.emit(this.current);
        this.messageToSendP = this.current;
        this.aantalpak = this.current;
    }

    addpak(): void {
        this.changepak(1);
        this.changeByTimer(1);
    }

    subpak(): void {
        this.changepak(-1);
        this.changeByTimer(-1);
    }

    inputpak(): void {
        this.changepak(0);
        //this.onChange(this.value);
    }

    writeValue(obj: any): void {
        if (typeof obj === 'number') {
            this.aantalpakken.nativeElement.value = obj.toString();
        } else {
            this.aantalpakken.nativeElement.value = '';
        }
    }

    private changepak(direction: number): void {
        // console.log(this.options.m2);

        const value =
            (!this.current || isNaN(this.current) ? 0 : this.current) +
            this.options.step * direction;

        // let value =
        //     (this.aantalpakken.nativeElement.value === '' ||
        //     isNaN(this.aantalpakken.nativeElement.value)
        //         ? 0
        //         : parseInt(this.aantalpakken.nativeElement.value)) +
        //     this.options.step * direction;

        // if (this.options.max !== null) {
        //     value = Math.min(this.options.max, value);
        // }

        //console.log(value);

        if (value !== this.value) {
            this.current = value;

            this.changeValues();
        }
    }

    /**
     * @param direction - one of [-1, 1]
     */
    private changeByTimer(direction: number): void {
        let interval;
        // const timer = setTimeout(() => {
        //     interval = setInterval(() => this.changem2(direction), 50);
        // }, 300);

        const documentMouseUp = () => {
            //            clearTimeout(timer);
            clearInterval(interval);

            document.removeEventListener('mouseup', documentMouseUp, false);
        };

        document.addEventListener('mouseup', documentMouseUp, false);
    }

    getSafeHTML(value: {}) {
        const json = JSON.stringify(value, null, 2);
        const html = `<script type="application/ld+json">${json}</script>`;
        // Inject to inner html without Angular stripping out content
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    sanitizeHtml(html: string) {
        return (this.sanitizedResourceUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(html));
        //return (this.sanitizedHtml =
        //    this.sanitizer.bypassSecurityTrustHtml(html));
    }

    getRoute(event) {
        if (!event.target.getAttribute('href').includes('http')) {
            event.preventDefault();
            this.router.navigate([event.target.getAttribute('href')]);
        }
    }

    public gotoAnchor(elementId): void {
        document.getElementById(elementId).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });

        //this.viewportScroller.scrollToAnchor(elementId);
    }

    ngAfterViewInit(): void {
        // Manually trigger change detection
        if (isPlatformBrowser(this.platformId)) {
            this.cdr.detectChanges();
        }
    }
}
