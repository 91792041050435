import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { CartService } from '../../services/cart.service';
import { Product, ProductAttribute } from '../../interfaces/product';
import { WishlistService } from '../../services/wishlist.service';
//import { CompareService } from '../../services/compare.service';
//import { QuickviewService } from '../../services/quickview.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CartModalService } from '../../services/cartmodal.service';
import { CommonModule } from '@angular/common';
import { CurrencyFormatPipe } from '../../pipes/currency-format.pipe';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
    selector: 'app-product-card',
    standalone: true,
    imports: [CommonModule, CurrencyFormatPipe, RouterLink, IconComponent],
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy, OnChanges {
    private destroy$: Subject<void> = new Subject();

    @Input() showWishList = true;
    @Input() product: Product;
    @Input() layout:
        | 'grid-sm'
        | 'grid-nl'
        | 'grid-lg'
        | 'list'
        | 'horizontal'
        | null = null;

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    featuredAttributes: ProductAttribute[] = [];
    serieGratisVloerShort: SafeHtml;

    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        //public compare: CompareService,
        //public quickview: QuickviewService,
        public currency: CurrencyService,
        private data: CartModalService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();
        });
        this.serieGratisVloerShort = this.sanitizer.bypassSecurityTrustHtml(this.product.serieGratisVloerShort);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('product' in changes) {
            this.featuredAttributes = !this.product
                ? []
                : this.product.attributes.filter((x) => x.featured);
        }
    }

    addToCart(): void {
        if (this.addingToCart) {
            return;
        }

        this.addingToCart = true;

        this.data.toModalCart('1-0-0');

        this.cart.add(this.product, 1, 0, 0, false).subscribe({
            complete: () => {
                this.addingToCart = false;
                this.cd.markForCheck();
            }
        });
    }

    addToWishlist(): void {
        if (this.addingToWishlist) {
            return;
        }

        this.addingToWishlist = true;
        this.data.toModalCart('1-0-0');

        this.wishlist.add(this.product).subscribe({
            complete: () => {
                this.addingToWishlist = false;
                this.cd.markForCheck();
            }
        });
    }

    // addToCompare(): void {
    //     if (this.addingToCompare) {
    //         return;
    //     }

    //     this.addingToCompare = true;
    //     this.compare.add(this.product).subscribe({
    //         complete: () => {
    //             this.addingToCompare = false;
    //             this.cd.markForCheck();
    //         }
    //     });
    // }

    // showQuickview(): void {
    //     if (this.showingQuickview) {
    //         return;
    //     }

    //     this.showingQuickview = true;
    //     this.quickview.show(this.product).subscribe({
    //         complete: () => {
    //             this.showingQuickview = false;
    //             this.cd.markForCheck();
    //         }
    //     });
    // }
}
