import { Component, Input } from '@angular/core';
import { Link } from '../../interfaces/link';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-page-header',
    standalone: true,
    imports: [RouterLink, IconComponent, CommonModule],
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
    @Input() header: string;
    @Input() breadcrumbs: Link[] = [];

    constructor() {}
}
