<!--

<input class="form-control input-number__input" type="number"
       [ngClass]="{'form-control-sm': size === 'sm', 'form-control-lg': size === 'lg'}"
       [min]="options.min"
       [max]="options.max"
       [value]="value"
       [disabled]="options.disabled"
       [readOnly]="options.readonly"
       (input)="input()"
       (blur)="onTouched()" #inputElement>

<div class="input-number__add" (mousedown)="add()"></div>
<div class="input-number__sub" (mousedown)="sub()"></div>


-->

<input class="form-control input-number__input" type="hidden" [value]="value" #inputElement>


<div class="row">
       <div class="col-4 col-lg-4 mx-0"  style="padding-right: 0px;">
              <div style="width: 100%; text-align: left;">
                     <div style="display: inline-grid;">
                            <span style=" font-size: 16px;" [innerHTML]="text1"></span>
                            <span style=" padding-top: 7px; display: block;">
                                   <div class="product__quantity  input-number" style="float: left; width: auto;">
                                          <input class="form-control input-number__input" type="number"
                                                 style="width: 60px; padding: 2px 6px 0px 6px;"
                                                 [ngClass]="{'form-control-sm': size === 'sm', 'form-control-lg': size === 'lg'}"
                                                 [min]="options.min" [max]="options.max" [value]="options.m2"
                                                 [disabled]="options.disabled" [readOnly]="options.readonly"
                                                 (input)="inputm2()" (blur)="onTouched()" onfocus="this.value=''" #aantalm2>

                                          <!-- <div class="input-number__add" (mousedown)="addm2()"></div>
                                          <div class="input-number__sub" (mousedown)="subm2()"></div> -->
                                   </div><div  style="float: left; padding: 3px 0px 0px 8px;"><strong>m<sup>2</sup></strong></div>
                            </span>
                     </div>
              </div>
       </div>
       <div class="col-4 col-lg-4 mx-0">
              <div style=" text-align: center;">
                     <div style="display: inline-grid; text-align: center;">
                            <span style="float: left;  clear: both; width: 100%;" [innerHTML]="text2"></span>
                            <span style="float: left; padding-top: 7px;">
                                   <div class="product__quantity input-number" style="width: 75px;">
                                          <input class="form-control input-number__input" type="number"
                                                 style="width: 60px; padding: 2px 6px 0px 6px;"
                                                 [ngClass]="{'form-control-sm': size === 'sm', 'form-control-lg': size === 'lg'}"
                                                 [min]="options.min" [max]="options.max" [value]="this.aantal"
                                                 [disabled]="options.disabled" [readOnly]="options.readonly"
                                                 (input)="inputpak()" (blur)="onTouched()" onfocus="this.value=''"
                                                 #aantalpak >

                                          <!-- <div class="input-number__add" (mousedown)="addpak()"></div>
                                          <div class="input-number__sub" (mousedown)="subpak()"></div> -->
                                   </div>

                            </span>
                     </div>
              </div>
       </div>
       <div class="col-4 col-lg-4 mx-0" style="padding-left: 0px;">
              <span style="text-align: right;   display: block; font-size: 16px; font-weight: bold; " [innerHTML]="text3"></span>
              <div style="text-align: right;  padding-top: 22px; text-align: right; display: block; font-size: 21px; font-weight: bold; "
                     #totaalprijs></div>


       </div>

</div>
