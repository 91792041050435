import {
    Component,
    OnInit,
    TemplateRef,
    Input,
    Output,
    EventEmitter,
    QueryList,
    ViewChildren,
    ElementRef
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Product } from '../../interfaces/product';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    FormControl,
    Validators
} from '@angular/forms';
import { concat } from 'rxjs';

import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}

@Component({
    selector: 'app-m2-calculator',
    standalone: true,
    imports: [
        CurrencyFormatPipe,
        ReactiveFormsModule,
        DecimalPipe,
        IconComponent,
        CommonModule
    ],
    templateUrl: './m2-calculator.component.html',
    styleUrls: ['./m2-calculator.component.scss']
})
export class M2CalculatorComponent implements OnInit {
    private dataProduct: Product;
    public fieldArrayInput: Array<any> = [];
    public fieldArray: Array<any> = [];
    public newAttributex: any = {};

    public quantity: any = {};

    public totaal = 0;
    public totaaloppervlakte = 0;
    public totaalsnijverlies = 0;
    public totaaloppervlakte2 = 0;
    public totaalpakken = 0;
    public snijverlies = false;

    public disableButtons = true;
    public isVisibleRuimteToevoegen = true;
    public isVisibleRuimteVerminderen = false;
    public isVisibleAddBttnStart = true;
    public isVisibleAddBttn = false;
    public isVisibleRemoveBttn = false;

    public fieldArrayInputBreedte = 0;
    public fieldArrayInputLengte = 0;

    @Output() notifyParent: EventEmitter<any> = new EventEmitter();
    @ViewChildren('imageElement', { read: ElementRef })
    imageElements: QueryList<ElementRef>;

    modalRef: BsModalRef;
    images: ProductImage[] = [];
    form: UntypedFormGroup = new UntypedFormGroup({});

    constructor(
        private modalService: BsModalService,
        private fb: UntypedFormBuilder
    ) {
        this.form = fb.group({
            number1: [
                '',
                [Validators.required, Validators.pattern('^[0-9]*$')]
            ],
            number2: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
        });
    }

    ngOnInit() {}

    @Input() set product(value: Product) {
        this.dataProduct = value;
        this.images = value
            ? this.dataProduct.images.map((url, index) => {
                  return {
                      id: index.toString(),
                      url,
                      active: index === 0
                  };
              })
            : [];
    }
    get product(): Product {
        // console.log(JSON.stringify(this.dataProduct));
        return this.dataProduct;
    }

    openModalWithClass(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-xl' })
        );

        if (JSON.parse(sessionStorage.getItem('m2calculatorarray')) !== null) {
            this.fieldArray = JSON.parse(
                sessionStorage.getItem('m2calculatorarray')
            );

            this.snijverlies = JSON.parse(
                sessionStorage.getItem('m2calculatorsnijverlies')
            );

            this.totaaloppervlakte = 0;
            for (let i = 0, len = this.fieldArray.length; i < len; i++) {
                if (this.fieldArray[i].breedte < 0) {
                    this.totaaloppervlakte =
                        this.totaaloppervlakte -
                        (Math.abs(this.fieldArray[i].breedte) *
                            this.fieldArray[i].lengte) /
                            10000;
                    //    console.log('11');
                } else {
                    this.totaaloppervlakte =
                        this.totaaloppervlakte +
                        (this.fieldArray[i].breedte *
                            this.fieldArray[i].lengte) /
                            10000;
                    //      console.log('22');
                }
            }

            if (this.snijverlies) {
                if (this.product.attributes[0].values[0].name == 'Visgraat') {
                    this.totaalsnijverlies = this.totaaloppervlakte * 0.1;
                    this.totaaloppervlakte =
                        this.totaaloppervlakte + this.totaalsnijverlies;
                } else {
                    this.totaalsnijverlies = this.totaaloppervlakte * 0.05;
                    this.totaaloppervlakte =
                        this.totaaloppervlakte + this.totaalsnijverlies;
                }
            }

            this.totaalpakken = this.totaaloppervlakte / this.product.m2;
            this.totaalpakken = Math.ceil(this.totaalpakken);

            this.fieldArrayInputBreedte = 0;
            this.fieldArrayInputLengte = 0;

            this.form.reset();

            this.isVisibleRemoveBttn = true;
        }
    }

    onSearchChange(): void {
        /*
        let len = "";

        if (!isNaN(this.newAttributex.name) && !isNaN(this.newAttributex.price)) {
            this.totaal = 0;

            for (let i = 0, len = this.fieldArray.length; i < len; i++) {
                this.totaal = this.totaal + (this.fieldArray[i].name * this.fieldArray[i].price);
            }
            this.totaal = this.totaal + (this.newAttributex.name * this.newAttributex.price);
            this.totaal = this.totaal / 10000;
            if (this.snijverlies) {
                this.totaal = this.totaal * 1.05;
            }
        }

        this.totaal = Math.round((this.totaal + Number.EPSILON) * 100) / 100;
        if (this.totaal > 0) { this.disableButtons = false; }*/
    }

    // addFieldValueB(event) {

    //     this.fieldArrayInputBreedte = event.target.value;

    //     if (!this.totaaloppervlakte2) {
    //         this.totaaloppervlakte2 = 0;
    //     }

    //     this.totaaloppervlakte =
    //         this.totaaloppervlakte2 +
    //         (this.fieldArrayInputBreedte * this.fieldArrayInputLengte) / 10000;

    //     if (this.snijverlies) {
    //         this.totaalsnijverlies = this.totaaloppervlakte * 0.05;
    //         this.totaaloppervlakte = this.totaaloppervlakte * 0.05;
    //     }
    //     this.totaaloppervlakte = parseFloat(this.totaaloppervlakte.toFixed(2));

    //     this.totaalpakken = this.totaaloppervlakte / this.product.m2;
    //     this.totaalpakken = Math.ceil(this.totaalpakken);

    // }

    // addFieldValueL(event) {

    //     this.fieldArrayInputLengte = event.target.value;

    //     if (!this.totaaloppervlakte2) {
    //         this.totaaloppervlakte2 = 0;
    //     }

    //     this.totaaloppervlakte =
    //         this.totaaloppervlakte2 +
    //         (this.fieldArrayInputBreedte * this.fieldArrayInputLengte) / 10000;

    //     if (this.snijverlies) {
    //         this.totaalsnijverlies = this.totaaloppervlakte * 0.05;
    //         this.totaaloppervlakte = this.totaaloppervlakte * 0.05;
    //     }
    //     this.totaaloppervlakte = parseFloat(this.totaaloppervlakte.toFixed(2));

    //     this.totaalpakken = this.totaaloppervlakte / this.product.m2;
    //     this.totaalpakken = Math.ceil(this.totaalpakken);

    // }

    negativeFieldValue() {
        /*   this.newAttributex.breedte = -Math.abs(this.newAttributex.breedte);
            this.fieldArray.pop();
            this.fieldArray.push(this.newAttributex);

            this.totaaloppervlakte = this.totaaloppervlakte + (this.newAttributex.breedte * this.newAttributex.lengte / 10000);
            console.log("B" + this.totaaloppervlakte);

            this.totaalpakken = this.totaaloppervlakte / this.product.m2;
            this.totaalpakken = Math.ceil(this.totaalpakken);
            this.newAttributex = {};
            this.isVisibleRuimteToevoegen = false;
            this.isVisibleRuimteVerminderen = false;
            this.isVisibleAddBttn = true;
            this.isVisibleRemoveBttn = true;
            this.form.reset();*/
    }

    RuimteToevoegen() {
        //        this.fieldArray.pop();
        //console.log("ZZ"+this.newAttributex.breedte);

        //console.warn(this.totaaloppervlakte);

        /*for (let i = 0; i < array.length; i++) {
            this.totaaloppervlakte + (this.fieldArrayInputBreedte * this.fieldArrayInputLengte / 10000);
         }*/
        //console.warn(this.totaaloppervlakte);

        this.fieldArray.push({
            breedte: this.form.get('number1').value,
            lengte: this.form.get('number2').value
            // breedte: this.fieldArrayInputBreedte,
            // lengte: this.fieldArrayInputLengte
        });

        sessionStorage.setItem(
            'm2calculatorarray',
            JSON.stringify(this.fieldArray)
        );

        this.totaaloppervlakte = 0;
        for (let i = 0, len = this.fieldArray.length; i < len; i++) {
            if (this.fieldArray[i].breedte < 0) {
                this.totaaloppervlakte =
                    this.totaaloppervlakte -
                    (Math.abs(this.fieldArray[i].breedte) *
                        this.fieldArray[i].lengte) /
                        10000;
                //    console.log('11');
            } else {
                this.totaaloppervlakte =
                    this.totaaloppervlakte +
                    (this.fieldArray[i].breedte * this.fieldArray[i].lengte) /
                        10000;
                //      console.log('22');
            }
        }

        if (this.snijverlies) {
            if (this.product.attributes[0].values[0].name == 'Visgraat') {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.1;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            } else {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.05;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            }
        }

        //console.log(JSON.stringify(this.fieldArray));
        //this.fieldArrayInput = [];
        //this.fieldArray.push(this.newAttributex);

        //this.newAttributex.breedte = '';
        //this.newAttributex.lengte = '';
        //if (!this.totaaloppervlakte) { this.totaaloppervlakte = 0; }
        //this.totaaloppervlakte2 = this.totaaloppervlakte;
        //  this.totaaloppervlakte = this.totaaloppervlakte + (this.fieldArrayInputBreedte * this.fieldArrayInputLengte / 10000);
        this.totaalpakken = this.totaaloppervlakte / this.product.m2;
        this.totaalpakken = Math.ceil(this.totaalpakken);

        //        console.warn(this.totaaloppervlakte);
        this.fieldArrayInputBreedte = 0;
        this.fieldArrayInputLengte = 0;

        this.form.reset();

        this.isVisibleRemoveBttn = true;
    }

    RuimteVerminderen() {
        //        this.fieldArray.pop();
        //console.log("ZZ"+this.newAttributex.breedte);

        //      console.warn(this.totaaloppervlakte);

        /*for (let i = 0; i < array.length; i++) {
            this.totaaloppervlakte + (this.fieldArrayInputBreedte * this.fieldArrayInputLengte / 10000);
         }*/
        //console.warn(this.totaaloppervlakte);

        this.fieldArray.push({
            breedte: -Math.abs(this.form.get('number1').value),
            lengte: this.form.get('number2').value
        });

        sessionStorage.setItem(
            'm2calculatorarray',
            JSON.stringify(this.fieldArray)
        );

        this.totaaloppervlakte = 0;
        for (let i = 0, len = this.fieldArray.length; i < len; i++) {
            if (this.fieldArray[i].breedte < 0) {
                this.totaaloppervlakte =
                    this.totaaloppervlakte -
                    (Math.abs(this.fieldArray[i].breedte) *
                        this.fieldArray[i].lengte) /
                        10000;
                //             console.log('11');
            } else {
                this.totaaloppervlakte =
                    this.totaaloppervlakte +
                    (this.fieldArray[i].breedte * this.fieldArray[i].lengte) /
                        10000;
                //              console.log('22');
            }
        }

        //console.log(JSON.stringify(this.fieldArray));
        if (this.snijverlies) {
            if (this.product.attributes[0].values[0].name == 'Visgraat') {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.1;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            } else {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.05;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            }
        }

        this.totaalpakken = this.totaaloppervlakte / this.product.m2;
        this.totaalpakken = Math.ceil(this.totaalpakken);

        this.fieldArrayInputBreedte = 0;
        this.fieldArrayInputLengte = 0;

        this.form.reset();
    }

    deleteFieldValue(index) {
        this.fieldArray.splice(index, 1);

        sessionStorage.setItem(
            'm2calculatorarray',
            JSON.stringify(this.fieldArray)
        );

        this.totaaloppervlakte = 0;
        for (let i = 0, len = this.fieldArray.length; i < len; i++) {
            if (this.fieldArray[i].breedte < 0) {
                this.totaaloppervlakte =
                    this.totaaloppervlakte -
                    (Math.abs(this.fieldArray[i].breedte) *
                        this.fieldArray[i].lengte) /
                        10000;
                console.log('11');
            } else {
                this.totaaloppervlakte =
                    this.totaaloppervlakte +
                    (this.fieldArray[i].breedte * this.fieldArray[i].lengte) /
                        10000;
                console.log('22');
            }
        }

        if (this.snijverlies) {
            if (this.product.attributes[0].values[0].name == 'Visgraat') {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.1;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            } else {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.05;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            }
        }
        this.totaalpakken = this.totaaloppervlakte / this.product.m2;
        this.totaalpakken = Math.ceil(this.totaalpakken);
    }

    closemodal() {
        //this.fieldArray = [];
        this.notifyParent.emit(this.totaalpakken);

        this.modalRef.hide();
    }

    public onChangeSnijverlies(value: boolean) {
        this.snijverlies = value;
        this.totaaloppervlakte = 0;

        sessionStorage.setItem(
            'm2calculatorsnijverlies',
            JSON.stringify(value)
        );

        for (let i = 0, len = this.fieldArray.length; i < len; i++) {
            if (this.fieldArray[i].breedte < 0) {
                this.totaaloppervlakte =
                    this.totaaloppervlakte -
                    (Math.abs(this.fieldArray[i].breedte) *
                        this.fieldArray[i].lengte) /
                        10000;
                console.log('11');
            } else {
                this.totaaloppervlakte =
                    this.totaaloppervlakte +
                    (this.fieldArray[i].breedte * this.fieldArray[i].lengte) /
                        10000;
                console.log('22');
            }
        }

        if (this.snijverlies) {
            if (this.product.attributes[0].values[0].name == 'Visgraat') {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.1;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            } else {
                this.totaalsnijverlies = this.totaaloppervlakte * 0.05;
                this.totaaloppervlakte =
                    this.totaaloppervlakte + this.totaalsnijverlies;
            }
        }
        this.totaalpakken = this.totaaloppervlakte / this.product.m2;
        this.totaalpakken = Math.ceil(this.totaalpakken);

        // this.snijverlies = value;
        // if (this.snijverlies) {
        //     if (this.product.attributes[0].values[0].name == 'Visgraat') {
        //         this.totaalsnijverlies = Math.ceil(
        //             this.totaaloppervlakte * 0.1
        //         );
        //         this.totaaloppervlakte = Math.ceil(
        //             this.totaaloppervlakte * 1.1
        //         );
        //     } else {
        //         this.totaalsnijverlies = Math.ceil(
        //             this.totaaloppervlakte * 0.05
        //         );
        //         this.totaaloppervlakte = Math.ceil(
        //             this.totaaloppervlakte * 1.05
        //         );
        //     }

        //     t;
        //     this.totaalpakken = this.totaaloppervlakte / this.product.m2;
        //     this.totaalpakken = Math.ceil(this.totaalpakken);
        // } else {
        //     this.totaalsnijverlies = 0;

        //     this.totaaloppervlakte = 0;
        //     for (let i = 0, len = this.fieldArray.length; i < len; i++) {
        //         if (this.fieldArray[i].breedte < 0) {
        //             this.totaaloppervlakte =
        //                 this.totaaloppervlakte -
        //                 (this.fieldArray[i].breedte *
        //                     this.fieldArray[i].lengte) /
        //                     10000;
        //             console.log('11');
        //         } else {
        //             this.totaaloppervlakte =
        //                 this.totaaloppervlakte +
        //                 (this.fieldArray[i].breedte *
        //                     this.fieldArray[i].lengte) /
        //                     10000;
        //             console.log('22');
        //         }
        //     }

        //     this.totaalpakken = this.totaaloppervlakte / this.product.m2;
        //     this.totaalpakken = Math.ceil(this.totaalpakken);
        // }
    }

    closecancel() {
        this.modalRef.hide();

        /*
        this.isVisibleRuimteToevoegen = true;

        this.isVisibleAddBttn = false;
        this.isVisibleRemoveBttn = false;
        this.totaaloppervlakte = 0; */
        //this.fieldArray = [];
    }

    get f() {
        return this.form.controls;
    }
}
