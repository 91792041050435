<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <!-- <button class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
        Snelweergeven
    </button>  -->

    <!-- <div *ngIf="product.badges.length" class="product-card__badges-list">
        <div *ngIf="product.badges.includes('sale')" class="product-card__badge product-card__badge--sale">Sale</div>
        <div *ngIf="product.badges.includes('hot')" class="product-card__badge product-card__badge--hot">Hot</div>
        <div *ngIf="product.badges.includes('new')" class="product-card__badge product-card__badge--new">New</div>
    </div> -->




    <div class="product-card__image">

    <div  *ngIf="product.gratisondervloertitle.includes('Gratis')  && product.merknaam == 'QuickStep'" class="product-card__badges-list">

        <div class="product-card__badge product-card__badge--gratisondervloer">Gratis<br>ondervloer</div>

    </div>


      <!-- print product.badges -->


      <div  *ngIf="product.badges.includes('20coreteckorting')" class="product-card__badges-list">

        <div class="product-card__badge product-card__badge--gratisondervloer" style="font-size: 16px;">Nu <strong>20%</strong><br>korting</div>

    </div>

    <div  *ngIf="product.badges.includes('gratisondervloerfloorify')" class="product-card__badges-list">

        <div class="product-card__badge product-card__badge--gratisondervloer" style="font-size: 16px;"><strong>Gratis</strong><br>ondervloer</div>

    </div>

    <div  *ngIf="product.badges.includes('gratisstalenfloorify')" class="product-card__badges-list">

        <div class="product-card__badge" style="border-radius: 10px; background: #f29b7f; color: #fff; font-size: 16px;"><strong style="color: #000;">Gratis</strong><br>stalen-service</div>

    </div>

    <!-- <div  *ngIf="product.badges.includes('gratisdecoupeerzaag')" class="product-card__badges-list">

        <div class="product-card__badge" style="border-radius: 10px; background: #DAEAE8; color: #404040; font-size: 14px;"><strong style="font-size: 18px;">Gratis</strong><br><strong>decoupeerzaag</strong></div>

    </div> -->


    <div  *ngIf="product.badges.includes('floer20megamat')" class="product-card__badges-list">

        <div class="product-card__badge" style="border-radius: 10px; background: #BE9FAF; color: #fff; font-size: 16px;">Extra <strong>10%</strong><br>korting</div>

    </div>



        <a appClick [routerLink]="'/product/'+product.id+'/'+product.slug" *ngIf="product.images?.length"><img style="width: 100%;"
                [src]="product.images[0]" alt="{{ product.name }}" loading="lazy"></a>
    </div>
    <div class="product-card__info">
        <div class="product-card__name">
            <a appClick [routerLink]="'/product/'+product.id+'/'+product.slug">{{ product.name }}</a>
        </div>

        <ul *ngIf="featuredAttributes.length" class="product-card__features-list">
            <ng-container *ngFor="let attribute of featuredAttributes">

                <ng-container *ngFor="let value of attribute.values; let last = last">
                    <ng-container *ngIf="value.name != '-'">
                        <li> {{ attribute.name }}: {{ value.name }}<ng-container *ngIf="!last">, </ng-container>
                        </li>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ul>
    </div>
    <div class="product-card__actions">
        <div class="product-card__availability">
            Levertijd:
            <span class="text-success">{{ product.availability }}</span>
        </div>
        <div class="product-card__prices" style="float: left; font-size: 21px; margin-top: 11px;">
            <!--<ng-container *ngIf="product.compareAtPrice">
                <span class="product-card__new-price">{{ product.price|currencyFormat }}</span> {{ ' ' }}
                <span class="product-card__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
            </ng-container>-->

            <ng-container *ngIf="!product.pricetape && !product.priceplint">
                {{ product.prijsm2|currencyFormat }} m<sup>2</sup>
            </ng-container>
            <ng-container *ngIf="product.pricetape && !product.priceplint">
                {{ product.pricetape|currencyFormat }}
            </ng-container>
            <ng-container *ngIf="product.priceplint && !product.pricetape">
                {{ product.priceplint|currencyFormat }} m<sup>1</sup>
            </ng-container>
            <!-- <ng-container *ngIf="product.prijspak == product.prijsm2">
                {{ product.prijspak|currencyFormat }}X4X
            </ng-container> -->



        </div>
 <button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist" type="button" *ngIf="showWishList"
                style="margin-left: 5px; background: #f0f0f0;" appClick (click)="addToWishlist()"
                [ngClass]="{'btn-loading': addingToWishlist}">
                <app-icon name="wishlist-16" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--wishlist-16"></span>
            </button>

        <!-- class="product-card__buttons" -->
        <div style="float: right;">
            <!--
                        <div class="form-check">
                            <span class="form-check-input input-check">
                                <span class="input-check__body">
                                    <input class="input-check__input" type="checkbox" data-toggle="tooltip" title="Vergelijken"
                                        appClick (click)="toggleCompare()" [(ngModel)]="onCompareList">
                                    <span class="input-check__box"></span>
                                    <app-icon class="input-check__icon" name="check-9x7" size="9x7"></app-icon>
                                </span>
                            </span>
                            <label class="form-check-label" for="login-remember"><span *ngIf="!onCompareList">
                                    vergelijken.</span><span *ngIf="onCompareList">Toegevoegd aan
                                    vergelijking.</span></label>&nbsp;<a *ngIf="anyOnCompareList || onCompareList"
                                href="/shop/compare">Bekijk vergelijking</a>
                        </div>-->



                        <button class="btn btn-primary product-card__addtocart" type="button" [routerLink]="'/product/'+product.id+'/'+product.slug"  appClick
                        [ngClass]="{'btn-loading': addingToCart}">
                        Info
                    </button>
            <!-- <button class="btn btn-primary product-card__addtocart" type="button" appClick (click)="addToCart()"
                [ngClass]="{'btn-loading': addingToCart}">
                <app-icon name="cart-20" size="20"></app-icon>
            </button> -->
            <!-- <button class="btn btn-primary product-card__addtocart product-card__addtocart--list" type="button" appClick
                (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">
                <app-icon name="cart-20" size="20"></app-icon>
            </button> -->


        </div>
        <div style="clear: both;"></div>

        <div style="text-align: center; width: 100%; padding-top: 12px;" *ngIf="product.serieGratisVloerShort == '' && product.gratisondervloertitle != '' && product.categorieid != 2" innerHTML="{{ product.gratisondervloertitle }}">
            </div>

        <div  style="text-align: center; width: 100%; padding-top: 12px; cursor: pointer;" *ngIf="product.serieGratisVloerShort != ''" [innerHTML]="serieGratisVloerShort">
             </div>

        <div style="text-align: center; width: 100%; padding-top: 12px; cursor: pointer;" *ngIf="product.serieGratisVloerShort == '' && product.gratisondervloertitle == '' && product.categorieid != 2 && product.categorieid != 1" >
            <a routerLink="/combinatie-korting">Combinatie-korting</a> op ondervloer</div>
        <div style="text-align: center; width: 100%; padding-top: 12px; cursor: pointer;" *ngIf="product.serieGratisVloerShort == '' && product.gratisondervloertitle == '' && product.categorieid == 1" >
            <a routerLink="/combinatie-korting" rel="nofollow">Combinatiekorting</a> op ondervloer</div>
        <div style="text-align: center; width: 100%; padding-top: 12px; cursor: pointer;" *ngIf="product.serieGratisVloerShort == '' && product.categorieid == 2" >
               <a routerLink="/handige-informatie/pvc-of-laminaat-vloer" >PVC vloeren informatie</a> </div>




        <!--<button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare" type="button" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}" style="float: right;">
            <app-icon name="compare-16" size="16"></app-icon>
            <span class="fake-svg-icon fake-svg-icon--compare-16"></span>
        </button>-->
        <!--
        <span class="input-check__body" style="float: right; width: 125px;">
            <input class="input-check__input" type="checkbox" data-toggle="tooltip" title="Vergelijken"
                appClick (click)="toggleCompare()" [(ngModel)]="onCompareList" style="float: left;">
            <span class="input-check__box"></span>
            <app-icon class="input-check__icon" name="check-9x7" size="9x7"></app-icon><span style="float: right;">Vergelijk</span>
        </span>-->





    </div>
</div>
