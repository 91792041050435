<div [innerHTML]="jsonLD"></div>



<div class="product product--layout--standard" *ngIf="product">


    <span class="d-md-none"
        style="font-size: 24px;  margin-bottom: 12px; font-weight: 700; display: block; color: #5e5351; line-height: 1.2;">{{
        product.name }}</span>

    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery">
            <div class="product-gallery">
                <div class="product-gallery__featured">
                    <owl-carousel-o [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)"
                        appOwlPreventClick #featuredCarousel ngSkipHydration>
                        <ng-template *ngFor="let image of images" carouselSlide [id]="image.id">
                            <a appClick (click)="openPhotoSwipe($event, image);" [href]="image.url" target="_blank">
                                <img [src]="image.url" alt="{{ product.name }}" #imageElement width="601" height="601"
                                    class="responsiveimg">
                            </a>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="product-gallery__carousel">
                    <owl-carousel-o [options]="thumbnailsCarouselOptions" [appFakeSlides]="images.length"
                        #fakeSlides="appFakeSlides" appOwlPreventClick #thumbnailsCarousel ngSkipHydration>
                        <ng-template *ngFor="let image of images" carouselSlide [id]="image.id">
                            <span class="product-gallery__carousel-item"
                                [ngClass]="{'product-gallery__carousel-item--active': image.active}" appClick
                                (click)="featuredCarousel.to(image.id); setActiveImage(image)">
                                <img class="product-gallery__carousel-image" [src]="image.url" alt="{{product.name}}"
                                    class="responsiveimg" width="89" height="89">
                            </span>
                        </ng-template>
                        <ng-template *ngFor="let i of fakeSlides.slides" carouselSlide [id]="'fake-slide-'+i">
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <br><span style="float: left; fill: #d1d1d1; padding: 5px 10px 0px 0px; fill: #0090e3;">

                    <svg width="9px" viewBox="0 0 63 99" aria-hidden="true" focusable="false">
                        <path
                            d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                        </path>
                    </svg>

                </span> <a routerLink="/{{product.categorienaam | lowercase }}/{{product.slugserie}}">Bekijk de
                    volledige
                    {{
                    product.merknaam }} {{
                    product.serienaam }}
                    collectie</a>

            </div>



            <br>


        </div>

        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip"
                    data-placement="right" title="Wishlist" appClick (click)="addToWishlist()"
                    [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip"
                    data-placement="right" title="Compare" appClick (click)="addToCompare()"
                    [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button>
            </div>

            <ng-container *ngIf="product.categorieid != 7">
                <a href="/handige-informatie/welke-ondervloer"><img src="/assets/images/logos/ondervloeren-wijzer.jpg"
                        class="d-none d-xl-block img-fluid" alt="Ondervloeren wijzer" style="float: right;" width="175"
                        height="90"></a></ng-container>
            <ng-container *ngIf="product.categorieid == 7">
                <a routerLink="/{{product.categorieslug| lowercase}}/{{product.slugmerk}}">
                    <img src="/assets/images/logos/merk-{{ product.merknaam.replace(' ', '-').replace(' & ', '-').toLowerCase() }}.jpg"
                        class="d-none d-xl-block img-fluid" alt="Logo {{ product.merknaam }}" style="float: right;"></a></ng-container>
            <h1 class="product__name">{{ product.name }}</h1>

        </div>


        <div class="product__sidebar">
            <!--
            <div class="product__availability">
                Levertijd: <span class="text-success">Op voorraad - 2 werkdagen</span>
            </div>
            -->
            <div class="product__prices d-none d-md-block">
                <ng-container *ngIf="product.prijsadvies">
                    <span class="product__new-price">{{ product.prijsm2|currencyFormat }}</span>{{ ' ' }}
                    <span class="product__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
                </ng-container>
                <ng-container *ngIf="!product.compareAtPrice">


                    <ng-container *ngIf="!product.uitvoeringtype || product.uitvoeringtype == 'stuk'">{{
                        product.prijspak|currencyFormat }}</ng-container>


                    <ng-container
                        *ngIf="product.uitvoeringtype && !product.pricetape && !product.priceplint && (product.uitvoeringtype == 'pak' || product.uitvoeringtype == 'rol')">
                        {{ product.prijsm2|currencyFormat }} / m<sup>2</sup>
                    </ng-container>
                    <ng-container *ngIf="product.uitvoeringtype && product.pricetape && !product.priceplint">
                        {{ product.pricetape|currencyFormat }}
                    </ng-container>
                    <ng-container *ngIf="product.uitvoeringtype && product.priceplint && !product.pricetape">
                        {{ product.priceplint|currencyFormat }} / m<sup>1</sup>
                    </ng-container>

                    <ng-container *ngIf="product.uitvoeringtype == 'plint'">
                        {{ product.prijsm2|currencyFormat }} / m<sup>1</sup>
                    </ng-container>



                    <br>
                    <div style="font-size: 16px; font-weight: normal; color: #000; padding-top: 10px;">
                        <ng-container *ngIf="product.uitvoeringtype != 'stuk'">Prijs per <span
                                [innerHTML]=" product.uitvoeringtype"></span> {{ product.prijspak |currencyFormat}}<br>
                        </ng-container>
                        Levertijd:
                        {{ product.availability }} werkdagen
                    </div>
                </ng-container>

            </div>

            <div class="product__prices d-md-none" style="text-align: right;">
                <ng-container *ngIf="product.prijsadvies">
                    <span class="product__new-price">{{ product.prijsm2|currencyFormat }}</span>{{ ' ' }}
                    <span class="product__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
                </ng-container>
                <ng-container *ngIf="!product.compareAtPrice">


                    <ng-container *ngIf="!product.uitvoeringtype || product.uitvoeringtype == 'stuk'">{{
                        product.prijspak|currencyFormat }}</ng-container>


                    <ng-container
                        *ngIf="product.uitvoeringtype && !product.pricetape && !product.priceplint && (product.uitvoeringtype == 'pak' || product.uitvoeringtype == 'rol')">
                        {{ product.prijsm2|currencyFormat }} / m<sup>2</sup>
                    </ng-container>
                    <ng-container *ngIf="product.uitvoeringtype && product.pricetape && !product.priceplint">
                        {{ product.pricetape|currencyFormat }}
                    </ng-container>
                    <ng-container *ngIf="product.uitvoeringtype && product.priceplint && !product.pricetape">
                        {{ product.priceplint|currencyFormat }} / m<sup>1</sup>
                    </ng-container>

                    <ng-container *ngIf="product.uitvoeringtype == 'plint'">
                        {{ product.prijsm2|currencyFormat }} / m<sup>1</sup>
                    </ng-container>



                    <br>
                    <div style="font-size: 16px; font-weight: normal; color: #000; padding-top: 10px;">
                        <ng-container *ngIf="product.uitvoeringtype != 'stuk'">Prijs per <span
                                [innerHTML]=" product.uitvoeringtype"></span> {{ product.prijspak |currencyFormat}}<br>
                        </ng-container>
                        Levertijd:
                        {{ product.availability }} werkdagen
                    </div>
                </ng-container>

            </div>

            <ng-container *ngIf="product.categorieid == 3">
                <br>
                <div class="product__description">
                    <span style="color: #305696; font-weight: bold; font-size: 20px;">Aanbieding
                    </span><br>
                    <span innerHTML="{{ product.gratisvloer }}"></span>

                    De ondervloer is vaak nog voordeliger in combinatie met aankoop laminaat of PVC vloer.<br>
                    Bekijk de <a routerLink="/combinatie-korting">combinatie korting</a> aanbieding.<br>
                    Ondervloer is uit voorraad leverbaar.
                </div>
                <br>
            </ng-container>

            <!-- <div class="product__description">
                <span style="font-weight: bold; font-size: 20px;">Bereken prijs</span><br>
                Teveel besteld? Gratis <a href="#">ophaalservice</a> volle pakken
                laminaat. Prijs per pak van {{product.m2}} m<sup>2</sup> slechts
                {{product.m2*product.price|currencyFormat}}.<br>
                <i>Bereken 5% extra snijverlies.</i>
            </div> -->




            <!-- <app-m2-calculator (notifyParent)="getMessage($event)"></app-m2-calculator> -->



            <div class="d-none d-lg-block"><br><br><br><br></div>

            <form class="product__options">



                <br>
                <div class="form-group product__option">

                    <!--
                    <app-calculate-m2 [receivedParentMessage]="messageToSendP" aria-label="Hoeveel" size="lg" [min]="1"
                        [formControl]="quantity" [m2]="product.m2" type="rol" [prijsm2]="product.prijsm2">
                    </app-calculate-m2>
-->

                    <div style="width: 100%;">
                        <div style="float: left;">
                            <span
                                style="float: left; padding-right: 10px; padding-top: 2px; display: block; font-size: 20px;"><strong>Aantal</strong></span>
                            <span style="float: left; padding-left: 10px;">

                                <span style="float: left; ">
                                    <div class="product__quantity input-number">

                                        <input class="form-control input-number__input" type="number"
                                            style="width: 90px; padding: 18px;" [min]="options.min" [max]="options.max"
                                            [readOnly]="options.readonly" [formControl]="quantity" (input)="inputpak()"
                                            (blur)="onTouched()" onfocus="this.value=''" [(ngModel)]="current">


                                        <!-- <div class="input-number__add" (mousedown)="addpak()"></div>
                                        <div class="input-number__sub" (mousedown)="subpak()"></div> -->
                                    </div>

                                </span>

                                <!-- <app-input-number aria-label="Hoevee" class="product__quantity" size="lg" [min]="1"
                                    [formControl]="quantity"></app-input-number> -->
                            </span>


                        </div>
                        <div style="float: right;">
                            <span style="text-align: right; padding-top: 2px; display: block; font-size: 20px;"><strong>Totaal:
                                    {{ (product.prijspak * this.aantalpak)|currencyFormat }}</strong></span>
                        </div>
                    </div>

                    <br>


                    <!--
                    <label class="product__option-label">Aantal pakketten</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Hoevee" class="product__quantity" size="lg" [min]="1"
                                [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item">
                            <app-input-number aria-label="Hoevee" class="product__quantity" size="lg" [min]="1"
                                [formControl]="quantity"></app-input-number>
                        </div>
                        Totaalprijs € 40,28
                    </div>
-->
                </div>

                <br>

                <div class="form-group product__option">

                    <div class="product__actions" style="float: right;">
                        <div class="product__actions-item product__actions-item--wishlist">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip"
                                title="Boodschappenlijst" [ngClass]="{'btn-loading': addingToWishlist}" appClick
                                (click)="addToWishlist()">
                                <app-icon name="wishlist-16" size="16"></app-icon>
                            </button>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart"
                            style="width: 100%; padding-left: 15px;">
                            <button type="button" class="btn btn-primary btn-lg" style="width: 100%;"
                                [ngClass]="{'btn-loading': addingToCart}" appClick (click)="addToCart()">Plaats in
                                winkelmand</button>
                        </div>

                        <!--
                        <div class="product__actions-item product__actions-item--compare">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip"
                                title="Vergelijken" [ngClass]="{'btn-loading': addingToCompare}" appClick
                                (click)="addToCompare()">
                                <app-icon name="compare-16" size="16"></app-icon>
                            </button>
                        </div>-->
                    </div>
                </div>
            </form>
            <!-- .product__options / end -->
            <br>
            <br>
            <br>
            <br>
            <div class="form-group">
                <div class="form-check">
                    <span class="form-check-input input-check">
                        <span class="input-check__body">
                            <input class="input-check__input" type="checkbox" data-toggle="tooltip" title="Vergelijken"
                                appClick (click)="toggleCompare()" [(ngModel)]="onCompareList"> <!-- checked -->
                            <span class="input-check__box"></span>
                            <app-icon class="input-check__icon" name="check-9x7" size="9x7"></app-icon>
                        </span>
                    </span>
                    <label class="form-check-label" for="login-remember"><span *ngIf="!onCompareList">Voeg toe aan
                            vergelijking.</span><span *ngIf="onCompareList">Toegevoegd aan
                            vergelijking.</span></label>&nbsp;<a *ngIf="anyOnCompareList || onCompareList"
                        href="/vergelijken">Bekijk vergelijking</a>
                </div>
            </div>

            <div *ngIf="this.blackfridayactie" class="d-none d-md-block d-lg-none">
                <img class="img-fluid" src="/assets/images/actie/blackfriday-374.jpg">
            </div>

            <div *ngIf="this.blackfridayactie" class="d-none d-lg-block">
                <img class="img-fluid" src="/assets/images/actie/blackfriday-608.jpg">
            </div>

            <br>




            <div class="checkmark" style="float: left; margin-right: 10px;"></div><a
                href="/klantenservice/bezorgen-afhalen" class="black"> <span class="orangebold">Bezorging</span> tot
                achter de deur</a><br>
            <div class="checkmark" style="float: left; margin-right: 10px;"></div><a
                href="/klantenservice/betaalmogelijkheden" class="black" style="font-weight: bold; color: #009600;">
                iDeal
                betaling <span class="orangebold" style="color: #009600;">extra {{ this.iDealKortingPercentage * 100 |
                    number: '1.0-0' }}% voordeel</span></a><br>
            <div class="checkmark" style="float: left; margin-right: 10px;"></div><a href="/klantenservice/retourneren"
                class="black"> <span class="orangebold">Gratis</span> binnen 30 dagen ophaalservice</a><br>
            <div class="checkmark" style="float: left; margin-right: 10px;"></div><a
                href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" class="black" target="_blank"
                rel="nofollow"> Klanten
                geven Voordeelvloeren&nbsp;<span class="orangebold"><ng-container
                        *ngIf="fbcreviews">{{fbcreviews['score']}}</ng-container></span></a><br>
            <br>


        </div>



    </div>

    <div *ngIf="this.blackfridayactie" class="mt-1 mb-3 d-block d-sm-none" style="width: 100%; text-align: center;">
        <img class="img-fluid" src="/assets/images/actie/blackfriday-374.jpg">
    </div>

    <div *ngIf="this.blackfridayactie" class="mt-1 mb-3 d-none d-sm-block d-md-none">
        <img class="img-fluid" src="/assets/images/actie/blackfriday-608.jpg">
    </div>



    <div class="row">
        <div class="col-12 col-lg-7 ">
            <div class="d-none d-md-block">
                <span innerHTML="{{ product.typedetail }}" (click)="getRoute($event)"></span>
                <br><br>
            </div>
        </div>
        <div class="d-md-none container">
            <div innerHTML="{{ product.typedetail }}" (click)="getRoute($event)"
                [ngClass]="{'limitTextHeight': isReadMore}">


            </div>

            <span style="float: left; padding: 5px 8px 0px 3px; fill: #0090e3;">

                <svg width="9px" viewBox="0 0 63 99" aria-hidden="true" focusable="false">
                    <path
                        d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
                    </path>
                </svg>

            </span><a style="padding-top: 1px; display: block" href="javascript:;" (click)="showText()">{{ isReadMore ?
                'Bekijk volledige omschrijving': 'Maak omschrijving korter' }}</a>
            <br><br>
        </div>

        <div class="col-12 col-lg-5" *ngIf="product.categorieid == 3 && product.merknaam != 'Accessoires'">
            <div class="short-div" style="background-color: #f9f7f7; padding: 8px;">


                <h3 class="block-header__title">Technische
                    informatie</h3>
                <table style="width: 100%;">
                    <tr>
                        <th class="th-productinfo">Levertijd</th>
                        <td>{{ product.availability }} dagen</td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Afmetingen</th>
                        <td>{{ product.attributes[3].values[0].name }}</td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Warmte isolatie</th>
                        <td>
                            <div class="notavailable" *ngIf="product.attributes[0].values[0].name == '1'"></div>
                            <img *ngIf="product.attributes[0].values[0].name == '2' || product.attributes[0].values[0].name == '3' || product.attributes[0].values[0].name == '4' || product.attributes[0].values[0].name == '5' || product.attributes[0].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[0].values[0].name == '3' || product.attributes[0].values[0].name == '4' || product.attributes[0].values[0].name == '5' || product.attributes[0].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[0].values[0].name == '4' || product.attributes[0].values[0].name == '5' || product.attributes[0].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[0].values[0].name == '5' || product.attributes[0].values[0].name == '6' "
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[0].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">

                            <img *ngIf="product.attributes[0].values[0].name == '5' || product.attributes[0].values[0].name == '4' || product.attributes[0].values[0].name == '3' || product.attributes[0].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[0].values[0].name == '4' || product.attributes[0].values[0].name == '3' || product.attributes[0].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[0].values[0].name == '3' || product.attributes[0].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[0].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Vloerverwarming</th>
                        <td>
                            <div class="notavailable" *ngIf="product.attributes[1].values[0].name == '1'"></div>
                            <img *ngIf="product.attributes[1].values[0].name == '2' || product.attributes[1].values[0].name == '3' || product.attributes[1].values[0].name == '4' || product.attributes[1].values[0].name == '5' || product.attributes[1].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[1].values[0].name == '3' || product.attributes[1].values[0].name == '4' || product.attributes[1].values[0].name == '5' || product.attributes[1].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[1].values[0].name == '4' || product.attributes[1].values[0].name == '5' || product.attributes[1].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[1].values[0].name == '5' || product.attributes[1].values[0].name == '6' "
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[1].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">

                            <img *ngIf="product.attributes[1].values[0].name == '5' || product.attributes[1].values[0].name == '4' || product.attributes[1].values[0].name == '3' || product.attributes[1].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[1].values[0].name == '4' || product.attributes[1].values[0].name == '3' || product.attributes[1].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[1].values[0].name == '3' || product.attributes[1].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[1].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Geluidsdemping in de ruimte</th>
                        <td>
                            <div class="notavailable" *ngIf="product.attributes[2].values[0].name == '1'"></div>
                            <img *ngIf="product.attributes[2].values[0].name == '2' || product.attributes[2].values[0].name == '3' || product.attributes[2].values[0].name == '4' || product.attributes[2].values[0].name == '5' || product.attributes[2].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[2].values[0].name == '3' || product.attributes[2].values[0].name == '4' || product.attributes[2].values[0].name == '5' || product.attributes[2].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[2].values[0].name == '4' || product.attributes[2].values[0].name == '5' || product.attributes[2].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[2].values[0].name == '5' || product.attributes[2].values[0].name == '6' "
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">
                            <img *ngIf="product.attributes[2].values[0].name == '6'"
                                src="/assets/images/global/ratingstar.svg" alt="Rating" style="vertical-align: middle;"
                                width="15" height="15">

                            <img *ngIf="product.attributes[2].values[0].name == '5' || product.attributes[2].values[0].name == '4' || product.attributes[2].values[0].name == '3' || product.attributes[2].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[2].values[0].name == '4' || product.attributes[2].values[0].name == '3' || product.attributes[2].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[2].values[0].name == '3' || product.attributes[2].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                            <img *ngIf="product.attributes[2].values[0].name == '2'"
                                src="/assets/images/global/ratingstar-leeg.svg" alt="Rating"
                                style="vertical-align: middle;" width="15" height="15">
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Ondergrond hout</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[4].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Ondergrond beton</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[5].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Ondergrond fermacell</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[6].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Toepassing begane grond</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[7].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Toepassing verdiepingsvloer</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[8].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Toepassing appartement</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[9].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Vloerkoeling</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[10].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Keurmerk TNO 10 dB</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[11].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Keurmerk TUV 10 dB</th>
                        <td>
                            <div class="notavailable"
                                *ngIf="product.attributes[12].values[0].name == ''; else elseBlock">
                            </div>
                            <ng-template #elseBlock>
                                <div class="checkmark"></div>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <th class="th-productinfo">Warmteweerstand</th>
                        <td>
                            {{ product.attributes[13].values[0].name }}
                        </td>
                    </tr>
                </table>


            </div>




            <!-- <div style="padding: 15px; ">
                <div class="short-di2v">

                    <span style="font-size: 18px; font-weight: bold; ">Downloads</span><br>
                    <img src="/assets/images/logos/pdf.svg" class="img-fluid" alt="PDF download" width="15px"
                        style="vertical-align: unset;">&nbsp;<a href="" class="black">Quick-Step
                        Laminaat</a><br>
                    <img src="/assets/images/logos/pdf.svg" class="img-fluid" alt="PDF download" width="15px"
                        style="vertical-align: unset;">&nbsp;<a href="" class="black">Vloerverwarming Quick-Step</a><br>
                    <img src="/assets/images/logos/pdf.svg" class="img-fluid" alt="PDF download" width="15px"
                        style="vertical-align: unset;">&nbsp;<a href="" class="black">Garantie Quick-Step</a><br>
                    <img src="/assets/images/logos/pdf.svg" class="img-fluid" alt="PDF download" width="15px"
                        style="vertical-align: unset;">&nbsp;<a href="" class="black">Prestatieverklaring Quick-Step
                    </a><br>
                </div>
            </div> -->
        </div>


        <!-- <div class="products-view__list products-list" [attr.data-layout]="'grid-3-sidebar'"
            [attr.data-with-features]="true">
            <div class="products-list__body">
                <div *ngFor="let product of obj " class="products-list__item">
                    <app-product-card [product]="product"></app-product-card>
                </div>
            </div>
        </div> -->

        <!-- && obj.length >= 1 -->


        <div class="col-12 col-lg-5" *ngIf="product.categorieid == 4 && (obj | json) != '[]' ">
            <div class="block-header">
                <h3 class="block-header__title">Ook in deze serie </h3>
                <div class="block-header__divider"></div>

            </div>
            <div class="products-view__list products-list" [attr.data-layout]="'grid-3-sidebar'"
                [attr.data-with-features]="true">
                <div class="products-list__body">

                    <ng-container *ngFor="let product2 of obj;">
                        <div *ngIf="product.id != product2.id" class=" products-list__item"
                            style="display: flex; min-width: 150px;">


                            <div class="product-card">

                                <div style="padding: 8px; flex-shrink: 0;"><a routerLink="{{product2.url}}"
                                        class="ng-star-inserted"><img loading="lazy" style="width: 100%;"
                                            alt="Plakplint Universeel 240 cm" src="{{product2.image}}"></a>

                                </div>
                                <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                    <div class="product-card__name"><a appclick=""
                                            ng-reflect-router-link="/product/573/plakplint-univers"
                                            routerLink="{{product2.url}}">{{product2.title}}</a>
                                    </div>

                                </div>
                                <div class="product-card__actions" style="width: 100%; text-align: center;">

                                    <div class="product-card__prices" [innerHtml]="product2.price"
                                        style=" font-size: 18px; padding: 5px 0px 8px 0px;">

                                    </div>
                                    <div><button type="button" routerLink="{{product2.url}}"
                                            class="btn btn-primary product-card__addtocart">
                                            Informatie
                                        </button></div>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>

            <br>
            <button type="button" style="width: 100%; height: auto;"
                routerLink="/{{product.categorienaam | lowercase }}/{{product.slugserie}}" class="btn btn-secondary">
                Bekijk de volledige {{
                product.merknaam }} {{
                product.serienaam }}
                collectie
            </button>

        </div>




    </div>

</div>
