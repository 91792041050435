import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PageCategoryService } from '../../../modules/shop/services/page-category.service';
import { Router } from '@angular/router';
import { SeoService } from '../../../shared/services/seo.service';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-pagination',
    standalone: true,
    imports: [IconComponent, CommonModule],
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PaginationComponent),
            multi: true
        }
    ]
})
export class PaginationComponent
    implements OnChanges, OnInit, ControlValueAccessor
{
    @Input() siblings = 1;
    @Input() current = 1;
    @Input() total = 1;

    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    pages: number[] = [];
    nextpages: string[] = [];

    onChange: any = () => {};
    onTouched: any = () => {};
    currentUrl: string;

    constructor(
        private pageService: PageCategoryService,
        private router: Router,
        private seoService: SeoService
    ) {}

    ngOnInit(): void {
        this.calc();
        this.currentUrl = this.router.url;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.calc();
    }

    setPage(value: number, emitEvent = true): void {
        this.onTouched();
        //console.log('VLAUE!' + value);
        if (value < 1 || value > this.total || value === this.current) {
            return;
        }

        if (this.current !== value) {
            this.onChange(value);
        }

        event.preventDefault();
        this.current = value;
        this.calc();

        this.pageService.options.page = this.current;

        //      if (emitEvent) {
        //          this.pageChange.emit(this.current);
        //      }

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    trackByFn(index: number): number {
        return index;
    }

    private calc(): void {
        const min = Math.max(
            1,
            this.current -
                this.siblings -
                Math.max(0, this.siblings - this.total + this.current)
        );
        const max = Math.min(this.total, min + this.siblings * 2);

        this.pages = [];
        this.nextpages = [];

        for (let i = min; i <= max; i++) {
            this.pages.push(i);

            if (i == 1) {
                this.nextpages[i] = this.router.url.replace(
                    /\?page=[0-9]+/,
                    ''
                );
            } else {
                this.nextpages[i] = this.router.url.replace(
                    /page=[0-9]+/,
                    'page=' + i
                );
            }
        }

        if (this.router.url.includes('page=')) {
            if (parseInt(this.router.url.match(/page=(\d*)/)[1]) <= max) {
                this.seoService.createLinkForPrevLink(
                    this.router.url.replace(
                        /page=[0-9]+/,
                        'page=' +
                            (parseInt(this.router.url.match(/page=(\d*)/)[1]) -
                                1)
                    )
                );
            }

            if (parseInt(this.router.url.match(/page=(\d*)/)[1]) < max) {
                this.seoService.createLinkForNextLink(
                    this.router.url.replace(
                        /page=[0-9]+/,
                        'page=' +
                            (parseInt(this.router.url.match(/page=(\d*)/)[1]) +
                                1)
                    )
                );
            }
        } else {
            if ((max) => 1) {
                this.seoService.createLinkForNextLink(
                    'https://www.voordeelvloeren.nl' +
                        this.router.url +
                        '?page=1'
                );
            }
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        if (typeof obj === 'number') {
            this.setPage(obj, false);
        }
    }
}
