import {
    AfterViewInit,
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    ViewChild
} from '@angular/core';
//import { QuickviewService } from '../../services/quickview.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
//import { Product } from '../../interfaces/product';
//import { Subject } from 'rxjs';
//import { takeUntil } from 'rxjs/operators';
import { CartService } from '../../../shared/services/cart.service';
import { GeneralModalService } from '../../services/generalmodal.service';
import { Subscription } from 'rxjs';
import { RootService } from '../../../shared/services/root.service';

@Component({
    selector: 'app-generalmodal',
    standalone: true,
    templateUrl: './generalmodal.component.html',
    styleUrls: ['./generalmodal.component.scss']
})
export class GeneralModalComponent implements OnInit, OnDestroy {
    // private destroy$: Subject<void> = new Subject();

    // @ViewChild('modal', { read: TemplateRef }) template: TemplateRef<any>;

    modalRef: BsModalRef;
    // product: Product;

    message: string;
    messages: string[];
    subscription: Subscription;

    productname: string;
    productimg: string;
    productcount: number;
    combinatiecount: number;
    combinatietype: number;
    combinatiename: string;
    combinatieimg: string;

    @ViewChild('generalmodal', { read: TemplateRef })
    generalmodal: TemplateRef<any>;

    constructor(
        private cart: CartService,
        private data: GeneralModalService,
        private modalService: BsModalService,
        public root: RootService
    ) {} // private quickview: QuickviewService,

    ngOnInit() {
        //console.log('a2s1d');

        this.subscription = this.data.currentMessage.subscribe((message) => {
            this.message = message;
            //console.log(this.message);

            //     //     this.message = message;

            //     if (message != '0-0-0') {
            //         this.messages = message.split('-');

            //         this.productcount = Number(this.messages[0]);

            //         this.combinatiecount = Number(this.messages[1]);

            //         this.combinatietype = Number(this.messages[2]);
            //     }

            if (this.message != '0-0-0') {
                this.openModal();
            }
        });

        //        this.openModal();

        // this.cart.onAdding$.subscribe((product) => {
        //     this.productname = product.name;
        //     this.productimg = product.images[0];
        //     this.combinatiename =
        //         product.combinatieondervloernaam[this.combinatietype];
        //     this.combinatieimg =
        //         product.combinatieondervloerimg[this.combinatietype];
        //     this.openModal();
        // });
    }

    openModal() {
        // console.log('333');

        this.modalRef = this.modalService.show(this.generalmodal);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    // clickWinkelmand

    // /bestellen

    //     modalRef.hide()

    // ngAfterViewInit(): void {
    //     this.quickview.show$
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe((product) => {
    //             if (this.modalRef) {
    //                 this.modalRef.hide();
    //             }

    //             this.product = product;
    //             this.modalRef = this.modalService.show(this.template, {
    //                 class: 'modal-dialog-centered modal-xl'
    //             });
    //         });
    // }

    // ngOnDestroy(): void {
    //     this.destroy$.next();
    //     this.destroy$.complete();
    // }
}
