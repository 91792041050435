<ng-template #generalmodal>
    <div class="modal-header">
        <h4 class="modal-title pull-left"><svg class="icon-check" aria-hidden="true" viewBox="0 0 32 32"
                focusable="false">
                <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32"></path>
                <path fill="#FFF"
                    d="M23.6 13.2l-7 9a2 2 0 0 1-3 .2l-5-5a2 2 0 1 1 2.8-2.8l3.4 3.4 5.6-7.2a2 2 0 1 1 3.2 2.4"></path>
            </svg> Filter: {{this.message}}</h4>
        <button type="button" class="close pull-right" style="fill: #ff7100; color: #ff7100; opacity: 1; font-size: 22px; display: contents; "
            aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">

        <ng-container *ngIf="this.message == 'kleur'">
            De vloeren worden gelevered in een grote variatie kleuren. Wat vind je mooi? Wat past bij interieur? Houd hierbij ook rekening met de lichtinval in de ruimte.
        </ng-container>
        <ng-container *ngIf="this.message == 'model'">
            De model worden gelevered in een grote variatie kleuren. Wat vind je mooi? Wat past bij interieur? Houd hierbij ook rekening met de lichtinval in de ruimte.
        </ng-container>
       {{this.message}}
    </div>
    <div class="modal-footer">


        <button type="button" class="btn btn-secondary btn-lg" style="width: 100%;" (click)="modalRef.hide()">Sluit venster</button>
    </div>
</ng-template>
