<button type="button" class="btn btn-secondary btn-nl" (click)="openModalWithClass(template)"
    style="background: #ff7100; color: #fff;">Oppervlakte calculator</button>


<ng-template #template>
    <div class="modal-header">
        <h2 class="modal-title pull-left" style="display: ruby;">Oppervlakte calculator</h2>
        <div style="width: 100%;">&nbsp;</div>
        <button type="button" class="close pull-right" style="fill: #ff7100; color: #ff7100; opacity: 1; font-size: 22px; display: contents;"
            aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body">



        <div class="container">
            <div class="row">
                <div class="col-1 d-none d-lg-block"><img src="{{images[0].url}}" alt="" #imageElement
                        style="float: left; width: 100%;">
                </div>
                <div class="col-11">
                    <div class="product__info">

                        <img src="/assets/images/logos/merk-{{ product.merknaam.replace(' ', '-').replace(' & ', '-').toLowerCase() }}.jpg"
                            class="d-none d-xl-block img-fluid" alt="Logo {{ product.merknaam }}" style="float: right;">
                        <h3 class="product__name">{{ product.name }}</h3>

                    </div>

                    <div class="product__prices">

                        <ng-container *ngIf="!product.compareAtPrice">
                            <span style="font-size: 18px; ">{{ product.prijsm2|currencyFormat }} m<sup>2</sup></span>
                            &nbsp;<span style="font-size: 16px; font-weight: normal; color: #000;">Levertijd:
                                {{ product.availability }} werkdagen</span>
                        </ng-container>

                    </div>
                </div>

            </div>
            <hr>

            <div class="row">
                <div class="col-12 col-lg-6" style="font-size: 14px; border-right: 2px solid #f8f4ee;">

                    <strong>Oppervlakte berekenen</strong><br>
                    Het aantal pakken of m2 dat je nodig hebt is eenvoudig te berekenen. Vul de maten van de
                    verschillende ruimtes in.
                    <br><br>
                    <strong>Ruimte toevoegen</strong>
                    <div
                        style="margin-left: 5px; float: right; background: #009600; color: #fff; padding: 0.175rem 0.5rem; position: relative; top: 9px;">

                        <span><img src="/assets/images/global/add.svg" width="10" style="position: relative;
                        top: -1px;"></span>

                    </div>
                    <br>
                    Vul de afmetingen in van de ruimte en kies "+". Daarna kan je dit opnieuw doen voor een extra
                    ruimte/oppervlak.<br><br>

                    <strong>Ruimte verminderen</strong>
                    <div
                        style="margin-left: 5px; float: right; background: #ff7100; color: #fff; padding: 0.175rem 0.5rem; position: relative; top: 9px;">

                        <span><img src="/assets/images/global/remove.svg" width="10" style="position: relative;
                        top: -1px;"></span>
                    </div>
                    <br>
                    Wil je een ruimte verminderen voor een keukenblok, kookeiland of deurmat?<br>
                    Vul de lengte en de breedte in en Kies "-".<br><br>

                    <strong>Snijverlies</strong><br>
                    Wij adviseren om
                    <ng-container *ngIf="product.attributes[0].values[0].name != 'Visgraat'">5</ng-container>
                    <ng-container *ngIf="product.attributes[0].values[0].name == 'Visgraat'">10</ng-container>% extra te bestellen omdat je tijdens het leggen van de vloer iets verzaagd of
                    net
                    niet mooi uitkomt met de plankmaat. Bestel je 5% extra dan heb je ruim voldoende om de vloer te
                    plaatsen. Selecteer "Bereken 5% snijverlies".
                    <br><br>
                    <strong>Ophaalservice</strong><br>
                    Heb je toch teveel besteld en volle pakken over, gebruik dan onze <a
                        routerLink="/klantenservice/retourneren">gratis
                        ophaalservice</a>.<div class="d-lg-none">
                        <hr>
                    </div>

                    <!-- Het aantal pakketten of m2 dat je nodig hebt is eenvoudig te berekenen door de maten van de ruimtes
                    die je wilt voorzien van een nieuwe vloer in te vullen in centimeters.<br><br>

                    Heb jij meer dan 1 ruimte, kies "+ ruimte toevoegen" en vul de lengte en de breedte in.<br><br>

                    Wil jij een ruimte verminderen voor een keukenblok, kookeiland of deurmat, kies " - ruimte
                    verminderen" en vul de lengte en de breedte in.<br><br>

                    We adviseren om 5% extra te bestellen omdat je tijdens het leggen van de vloer iets verzaagd of net
                    niet mooi uitkomt met de plankmaat. Bestel je 5% extra dan heb je ruim voldoende om de vloer te
                    plaatsen. Selecteer "Bereken 5% snijverlies".<br><br>

                    Het handige systeem telt de extra ruimtes en snijverlies op en geeft het aantal pakken en m2 direct
                    aan.<br><br>

                    Heb je toch teveel besteld en volle pakken over, gebruik dan onze <a href="/klantenservice/retourneren">gratis
                        ophaalservice</a>. -->










                </div>
                <div class="col-12 col-lg-6">

                    <!-- <div class="d-lg-none">&nbsp;</div> -->



                    <div id="ruimtetoevoegen">
                        <form [formGroup]="form">
                            <div class="row specialrow">


                                <div class="col-4 form-group">
                                    <label for="number">Breedte (cm)</label>
                                    <input formControlName="number1" inputmode="numeric" pattern="[0-9]*" id="number1"
                                        type="text" class="form-control" placeholder="0">
                                    <!--(keyup)="addFieldValueB($event)"-->
                                    <div *ngIf="f.number1.touched && f.number1.invalid" class="alert alert-danger">
                                        <div *ngIf="f.number1.errors.required">Veld is verplicht.</div>
                                        <div *ngIf="f.number1.errors.pattern">Graag een getal invoeren.</div>
                                    </div>
                                </div>

                                <div class="col-4 form-group">
                                    <label for="number">Lengte (cm)</label>
                                    <input formControlName="number2" inputmode="numeric" pattern="[0-9]*" id="number2"
                                        type="text" class="form-control" placeholder="0">
                                    <!--(keyup)="addFieldValueL($event)"-->
                                    <div *ngIf="f.number2.touched && f.number2.invalid" class="alert alert-danger">
                                        <div *ngIf="f.number2.errors.required">Veld is verplicht.</div>
                                        <div *ngIf="f.number2.errors.pattern">Graag eem getal invoeren.</div>
                                    </div>
                                </div>

                                <div class="col-2 form-group">
                                    <div class="d-none d-lg-block"><label>Toevoegen</label></div>
                                    <div class="d-lg-none"><label>&nbsp;&nbsp;&nbsp;</label></div>

                                    <button class="btn btn-default" type="button" (click)="RuimteToevoegen()" style="background: #009600; color: #fff; padding: 0.375rem 0.75rem;
                                    ">
                                        <span><img src="/assets/images/global/add.svg" width="21" style="position: relative;
                                        top: -1px;"></span>
                                    </button>
                                </div>

                                <div class="col-2 form-group">
                                    <ng-container *ngIf="isVisibleRemoveBttn">
                                        <div class="d-none d-lg-block"><label>Verminderen</label></div>
                                        <div class="d-lg-none"><label>&nbsp;&nbsp;&nbsp;</label></div>
                                        <button class="btn btn-default" type="button" (click)="RuimteVerminderen()"
                                            style="background: #ff7100; color: #fff; padding: 0.375rem 0.75rem;
                                    ">
                                            <span><img src="/assets/images/global/remove.svg" width="21" style="position: relative;
                                        top: -1px;"></span>
                                        </button>
                                    </ng-container>
                                </div>
                                <!--
                            <div class="col-6">
                                <input class="form-control" type="text" (input)="onSearchChange()"
                                    id="newAttributePrice" [(ngModel)]="newAttributex.breedtex"
                                    name="newAttributeBreedte" placeholder="Breedte (cm)" />
                            </div>
                            <div class="col-6">
                                <input class="form-control" type="text"  (input)="onSearchChange()"
                                    id="newAttributePrice" [(ngModel)]="newAttributex.lengtex" name="newAttributeLengte"
                                    placeholder="Lengte (cm)" />
                            </div>
                            -->
                            </div>

                            <!-- <div class="row" [style.display]="isVisibleAddBttnStart ? 'flex' : 'none'">

                                <div class="col-12" style="text-align: center;">
                                    <button class="btn btn-default" type="button" (click)="addFieldValue()"
                                        [disabled]="!form.valid" style="background: #ff7100; color: #fff;"><i
                                            class="fas fa-plus"></i>
                                        Ruimte toevoegen</button>
                                </div>
                            </div> -->
                        </form>
                    </div>
                    <!--
                    <div id="ruimteverminderen" [style.display]="isVisibleRuimteVerminderen ? 'block' : 'none'">
                        <form [formGroup]="form">
                        <div class="row">

                            <div class="col-6 form-group">
                                <label for="number">Breedte (cm)</label>
                                <input formControlName="number1" id="number1" type="text" class="form-control" [(ngModel)]="newAttributex.breedte"  (keypress)="onKeyPress($event)">
                                <div *ngIf="f.number1.touched && f.number1.invalid" class="alert alert-danger">
                                    <div *ngIf="f.number1.errors.required">Veld is verplicht.</div>
                                    <div *ngIf="f.number1.errors.pattern">Graag een getal invoeren.</div>
                                </div>
                            </div>

                            <div class="col-6 form-group">
                                <label for="number">Lengte (cm)</label>
                                <input formControlName="number2" id="number2" type="text" class="form-control"  [(ngModel)]="newAttributex.lengte"  (keypress)="onKeyPress($event)">
                                <div *ngIf="f.number2.touched && f.number2.invalid" class="alert alert-danger">
                                    <div *ngIf="f.number2.errors.required">Veld is verplicht.</div>
                                    <div *ngIf="f.number2.errors.pattern">Graag eem getal invoeren.</div>
                                </div>
                            </div>


                        </div>

                        <div class="row">

                            <div class="col-12" style="text-align: center;">
                                <button class="btn btn-default" type="button" (click)="negativeFieldValue()" [disabled]="!form.valid"
                                style="background: #ff7100; color: #fff;"><i class="fas fa-plus"></i>
                                Ruimte toevoegen</button>

                            </div>
                        </div>
                        </form>
                    </div>-->

                    <!-- <div class="row">
                        <div class="col-6 px-0" style="text-align: center;">
                            <button class="btn btn-default" type="button" (click)="RuimteToevoegen()" style="background: #ff7100; color: #fff;
                                "><i class="fas fa-plus"></i>
                                Ruimte <span class="d-xl-none" style="float: right;">&nbsp;<img
                                        src="/assets/images/global/add.svg" width="14" style="position: relative;
                                    top: -1px;"></span>
                                <span class="d-none d-xl-block" style="float: right;">&nbsp;toevoegen</span></button>
                        </div>
                        <div class="col-6 px-0" style="text-align: center;">
                            <button [style.display]="isVisibleRemoveBttn ? 'inline' : 'none'" class="btn btn-default"
                                type="button" (click)="openRuimteVerminderen()" style="background: #ff7100; color: #fff;
                                "><i class="fas fa-minus"></i>
                                Ruimte <span class="d-xl-none" style="float: right;">&nbsp;<img
                                        src="/assets/images/global/remove.svg" width="14" style="position: relative;
                                    top: -1px;"></span>
                                <span class="d-none d-xl-block" style="float: right;">&nbsp;verwijderen</span></button>
                        </div>
                    </div> -->
                    <!-- *ngIf="totaaloppervlakte > 0" -->
                    <ng-container *ngIf="totaaloppervlakte > 0">
                        <!-- <div class="clearfix">&nbsp;</div> -->
                        <div class="row specialrow" *ngFor="let field of fieldArray; let i = index; ">
                            <div class="col-7 align-self-center">
                                <span style="float: left;">R</span><span class="d-none d-sm-block"
                                    style="float: left;">uimte&nbsp;</span> {{i+1}}: {{field.breedte}} x
                                {{field.lengte}} cm
                            </div>

                            <div class="col-4 align-self-center" style="text-align: right;">
                                {{field.breedte * field.lengte / 10000 | number : '1.2-2'}} m<sup>2</sup>
                            </div>

                            <div class="col-1"> <button type="button" class="close pull-right"
                                    style="fill: #ff7100; color: #ff7100; opacity: 1; font-size: 22px; display: contents;" aria-label="Close"
                                    (click)="deleteFieldValue(i)">
                                    <span aria-hidden="true">×</span>
                                </button></div>

                        </div>
                        <div class="row specialrow"
                            *ngIf="fieldArray.length == 0 || fieldArrayInputBreedte != 0 || fieldArrayInputLengte != 0">
                            <div class="col-7 align-self-center">
                                <span style="float: left;">R</span><span class="d-none d-sm-block"
                                    style="float: left;">uimte&nbsp;</span> {{fieldArray.length+1}}:
                                {{ fieldArrayInputBreedte}} x {{ fieldArrayInputLengte}} cm
                            </div>

                            <div class="col-4 align-self-center" style="text-align: right;">
                                {{fieldArrayInputBreedte * fieldArrayInputLengte / 10000 | number : '1.2-2'}}
                                m<sup>2</sup>
                                <!-- <div style="float: right; width: 16px; display: block;">x&nbsp;</div> -->
                            </div>
                            <div class="col-1"></div>
                        </div>
                        <div class="row specialrow" *ngIf="this.snijverlies">
                            <div class="col-8 align-self-center" style="text-align: right;">
                                <ng-container *ngIf="product.attributes[0].values[0].name != 'Visgraat'">
                                    5% snijverlies
                                </ng-container>
                                <ng-container *ngIf="product.attributes[0].values[0].name == 'Visgraat'">
                                    10% snijverlies
                                </ng-container>
                            </div>
                            <div class="col-3 align-self-center" style="text-align: right;">
                                {{ totaalsnijverlies | number : '1.2-2'}} m<sup>2</sup>
                            </div>
                            <div class="col-1" style="text-align: right;">
                            </div>
                        </div>
                        <div class="row specialrow" style="border-top: 2px solid #f8f4ee;">
                            <div class="col-8 align-self-center" style="text-align: right;">
                                Totaal oppervlakte
                            </div>
                            <div class="col-3 align-self-center" style="text-align: right;">
                                {{ totaaloppervlakte | number : '1.2-2'}} m<sup>2</sup>
                                <!-- <div style="float: right; width: 16px; display: block;">&nbsp;</div> -->
                            </div>
                            <div class="col-1"></div>
                        </div>
                        <div class="clearfix">&nbsp;</div>
                    </ng-container>
                    <div class="row specialrow">

                        <div class="col-12 col-sm-5">


                            <div class="form-group">
                                <div class="form-check snijverlies" >
                                    <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" type="checkbox" data-toggle="tooltip"
                                                title="Snijverlies berekenen" [checked]="snijverlies"
                                                (change)="onChangeSnijverlies(snijverliesbox.checked)" #snijverliesbox
                                                id="snijverliesbox"> <!-- checked -->
                                            <span class="input-check__box"></span>
                                            <app-icon class="input-check__icon" name="check-9x7" size="9x7"></app-icon>
                                        </span>
                                    </span>
                                    <label class="form-check-label" for="login-remember">

                                        <ng-container *ngIf="product.attributes[0].values[0].name != 'Visgraat'">
                                            5% snijverlies berekenen.
                                        </ng-container>
                                        <ng-container *ngIf="product.attributes[0].values[0].name == 'Visgraat'">
                                            10% snijverlies berekenen.
                                        </ng-container>

                                        </label>
                                </div>
                            </div>






                            <!-- <input #snijverliesbox id="snijverliesbox" type="checkbox" class="form-control"
                                [checked]="snijverlies" (change)="onChangeSnijverlies(snijverliesbox.checked)"
                                style="float: left; width: 30px; height: 1.5em;"> 5% snijverlies berekenen -->
                        </div>
                        <div class="col-12 col-sm-6" style="text-align: right; font-size: 16px; font-weight: bold;">
                            Aantal benodigde pakken: {{ totaalpakken }}
                        </div>
                        <div class="col-1"></div>
                    </div>




                </div>
            </div>





        </div>


        <!--
        <input class="form-control" type="text" (input)="onSearchChange()" id="newAttributePrice"
            [(ngModel)]="newAttributex.price" name="newAttributePrice" placeholder="Breedte 1230 cm" /><br><input
            class="form-control" type="text" (input)="onSearchChange()" id="newAttributePrice"
            [(ngModel)]="newAttributex.price" name="newAttributePrice" placeholder="Lengte 1230 cm" />




        <button class="btn btn-default" type="button" (click)="addFieldValue()"
            style="background: #ff7100; color: #fff;"><i class="fas fa-minus"></i>
            Ruimte verminderen</button>

        <button class="btn btn-default" type="button" (click)="addFieldValue()"
            style="background: #ff7100; color: #fff;"><i class="fas fa-minus"></i>
            Ruimte verminderen</button>

        <button class="btn btn-default" type="button" (click)="addFieldValue()"
            style="background: #ff7100; color: #fff;"><i class="fas fa-plus"></i>
            Snijverlies 5% extra</button>

-->


        <!--
        <div class="container">


            <table class="table table-bordered">

                <tbody>

                    <tr>
                        <td class="col-6">
                            Oppervlak ruimte 1
                        </td>
                        <td class="col-4" style="text-align: right;">
                            12.35 m2
                        </td>
                        <td class="col-2" style="text-align: center;">
                            <i class="fas fa-times"></i>
                        </td>
                    </tr>

                    <tr>
                        <td class="col-6">
                            Oppervlak ruimte 2
                        </td>
                        <td class="col-4" style="text-align: right;">
                            46.25 m2
                        </td>
                        <td class="col-2" style="text-align: center;">
                            <i class="fas fa-times"></i>
                        </td>
                    </tr>
                    <tr>
                        <td class="col-6">
                            Lengte ruimte 1
                        </td>
                        <td class="col-4">
                            <input class="form-control" type="text" (input)="onSearchChange()" id="newAttributeName"
                                [(ngModel)]="newAttributex.name" name="newAttributeName" />
                        </td>
                        <td class="col-2" style="text-align: center;">
                            <i class="fas fa-times"></i>
                        </td>
                    </tr>
                    <tr>
                        <td class="col-6">
                            Breedte ruimte 2
                        </td>
                        <td class="col-4">
                            <input class="form-control" type="text" (input)="onSearchChange()" id="newAttributePrice"
                                [(ngModel)]="newAttributex.price" name="newAttributePrice" />
                        </td>
                        <td class="col-2" style="text-align: center;">

                        </td>
                    </tr>

                    <tr>
                        <td class="col-6">
                            Extra ruimte toevoegen
                        </td>
                        <td class="col-4">
                            <input class="input-check__input2" type="checkbox" id="checkout-different-address">
                        </td>
                        <td class="col-2" style="text-align: center;">

                        </td>
                    </tr>
                    <tr>
                        <td class="col-6">
                            Ruimte aftrekken keukenblok, wc, ....
                        </td>
                        <td class="col-4">
                            <input class="input-check__input2" type="checkbox" id="checkout-different-address">
                        </td>
                        <td class="col-2" style="text-align: center;">

                        </td>
                    </tr>

                    <tr>
                        <td class="col-6">
                            Bereken 5% snijverlies
                        </td>
                        <td class="col-4">
                            <input class="input-check__input2" type="checkbox" id="checkout-different-address">
                        </td>
                        <td class="col-2" style="text-align: center;">

                        </td>
                    </tr>

                </tbody>
            </table>



        </div>







        <br><br><br><br><br>
        <hr>






        <div class="container">Hier staat een uitleg asd asdkjahsd kjha sdhjkasdkjhasdhjk ahjkasdkhjasd hjka sdhjka
            sdhjkasdjkh.</div>
        <br>
        <div class="container">



            <table class="table table-bordered">

                <tbody>
                    <tr *ngFor="let field of fieldArray; let i = index">

                        <td class="col-6">
                            Oppervlakte ruimte 1
                        </td>
                        <td class="col-4">
                            {{field.price}} m2
                        </td>
                        <td class="col-2" style="text-align: center;">
                            <button class="btn btn-default" type="button" (click)="deleteFieldValue(i)"
                                style="background: #ff7100; color: #fff;"><i class="fas fa-minus"></i></button>
                        </td>
                    </tr>
                    <tr>
                        <td class="col-6">
                            Lengte ruimte 1
                        </td>
                        <td class="col-4">
                            <input class="form-control" type="text" (input)="onSearchChange()" id="newAttributeName"
                                [(ngModel)]="newAttributex.name" name="newAttributeName" />
                        </td>
                        <td class="col-2" style="text-align: center;">

                        </td>
                    </tr>
                    <tr>
                        <td class="col-6">
                            Breedte ruimte 2
                        </td>
                        <td class="col-4">
                            <input class="form-control" type="text" (input)="onSearchChange()" id="newAttributePrice"
                                [(ngModel)]="newAttributex.price" name="newAttributePrice" />
                        </td>
                        <td class="col-2" style="text-align: center;">
                            <button class="btn btn-default" type="button" (click)="addFieldValue()"
                                style="background: #009600; color: #fff;"><i class="fas fa-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>


            <div class="container">
                <span style="font-size: 14px; font-style: italic;">Vul negatieve getallen in (-123) om een ruimte af te
                    trekken van het totaal.</span>

                <br><br>

                <table class="order-list" style="width: 100%;">

                    <tbody>

                        <tr>
                            <td class="col-sm-4">

                            </td>
                            <td class="col-sm-3">

                            </td>
                            <td colspan="2" class="col-sm-5">

                                <span class="form-check-input input-check">
                                    <span class="input-check__body">
                                        <input class="input-check__input" type="checkbox"
                                            id="checkout-different-address" (change)="onSearchChange()"
                                            [(ngModel)]="snijverlies">
                                        <span class="input-check__box"></span>
                                        <svg class="input-check__icon" width="9px" height="7px">
                                            <use xlink:href="assets/images/sprite.svg#check-9x7"></use>
                                        </svg>

                                    </span>
                                </span>



                                Bereken 5% snijverlies
                            </td>

                        </tr>
                        <tr>
                            <td class="col-sm-4">

                            </td>
                            <td class="col-sm-3">

                            </td>
                            <td class="col-sm-3" style="font-weight: bold;">
                                Totaal
                            </td>
                            <td class="col-sm-2" style="font-weight: bold;"><a class="deleteRow"></a>
                                {{totaal}} m2
                            </td>
                        </tr>

                    </tbody>

                </table>

            </div>

        </div>



    </div> -->
        <!--
  <div class="modal-body">







      <div class="block">
          <div class="container">

              <div class="row">
                  <div class="col-lg-4">
                  </div>
                  <div class="col-lg-4">
                      <p>Oppervlakte ruimte 1 </p>
                  </div>
                  <div class="col-lg-4">12.35 m2 <button type="button" class="close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-4">
                  </div>
                  <div class="col-lg-4">
                      <p>Oppervlakte ruimte 2 </p>
                  </div>
                  <div class="col-lg-4">12.35 m2<button type="button" class="close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
              </div>

              <div class="row">
                  <div class="col-lg-4">
                  </div>
                  <div class="col-lg-4"><label>lengte van de ruimte 3</label><input type="text"
                          class="form-control" placeholder="Lengte">
                  </div>
                  <div class="col-lg-4"> <label>Breedte van de ruimte 3</label><input type="text"
                          class="form-control" placeholder="Breedte">
                  </div>

              </div>

              <div class="row">
                  <div class="col-lg-4">
                  </div>
                  <div class="col-lg-8">
                      <div class="form-group">
                          <div class="form-check">
                              <span class="form-check-input input-check">
                                  <span class="input-check__body">
                                      <input class="input-check__input" type="checkbox"
                                          id="checkout-create-account">
                                      <span class="input-check__box"></span>
                                      <svg class="input-check__icon" width="9px" height="7px">
                                          <use xlink:href="assets/images/sprite.svg#check-9x7"></use>
                                      </svg>
                                  </span>
                              </span>
                              <label class="form-check-label" for="checkout-create-account">Create an
                                  account?</label>
                          </div>
                      </div>
                  </div>


              </div>

              <div class="row">
                  <div class="col-lg-4">
                  </div>
                  <div class="col-lg-8">
                      <div class="form-group">
                          <div class="form-check">
                              <span class="form-check-input input-check">
                                  <span class="input-check__body">
                                      <input class="input-check__input" type="checkbox"
                                          id="checkout-create-account">
                                      <span class="input-check__box"></span>
                                      <svg class="input-check__icon" width="9px" height="7px">
                                          <use xlink:href="assets/images/sprite.svg#check-9x7"></use>
                                      </svg>
                                  </span>
                              </span>
                              <label class="form-check-label" for="checkout-create-account">Ruimte
                                  aftrekken keukenblok, wc</label>
                          </div>
                      </div>
                  </div>


              </div>

              <div class="row">
                  <div class="col-lg-4">
                  </div>
                  <div class="col-lg-8">
                      <div class="form-group">
                          <div class="form-check">
                              <span class="form-check-input input-check">
                                  <span class="input-check__body">
                                      <input class="input-check__input" type="checkbox"
                                          id="checkout-create-account">
                                      <span class="input-check__box"></span>
                                      <svg class="input-check__icon" width="9px" height="7px">
                                          <use xlink:href="assets/images/sprite.svg#check-9x7"></use>
                                      </svg>
                                  </span>
                              </span>
                              <label class="form-check-label" for="checkout-create-account">Bereken 5%
                                  snijverlies</label>
                          </div>
                      </div>
                  </div>


              </div>


          </div>
      </div>




  </div>
-->

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-secondary" (click)="closecancel()">Annuleren</button>

        <ng-container *ngIf="totaalpakken > 0" >
        <button type="button" class="btn btn-default btn-primary" (click)="closemodal()"
            ng-class="{'disabled': disableButtons}">Bereken prijs {{totaalpakken}} <span *ngIf="totaalpakken == 1">pak</span><span *ngIf="totaalpakken > 1">pakken</span> </button>
        </ng-container>
    </div>
</ng-template>
