import {
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    Input,
    ViewChild,
    SimpleChanges,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';

//import { exists } from 'fs';

function parseNumber<T>(value: any, def: T): number | T {
    if (typeof value === 'string') {
        value = parseFloat(value);
    } else if (typeof value !== 'number') {
        value = def;
    }

    return isNaN(value) ? def : value;
}

@Component({
    selector: 'app-calculate-m2',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './input-calculate-m2.component.html',
    styleUrls: ['./input-calculate-m2.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalculateM2Component),
            multi: true
        }
    ]
})
export class CalculateM2Component implements ControlValueAccessor, OnInit {
    options = {
        step: 1,
        min: null,
        max: null,
        disabled: false,
        readonly: false,
        m2: null,
        prijsm2: null,
        prijspak: null,
        uitvoeringtype: null
    };

    text1: string;
    text2: string;
    text3: string;

    aantal = 1;

    @Input() receivedParentMessage: string;

    //@HostBinding('class.input-number') class = true;

    @Input() size: 'sm' | 'lg' = null;

    @Input() set step(value: number) {
        this.options.step = parseNumber(value, 1);
    }

    @Input() set min(value: number) {
        this.options.min = parseNumber(value, null);
    }

    @Input() set m2(value: number) {
        this.options.m2 = parseNumber(value, null);
    }

    @Input() set uitvoeringtype(value: string) {
        this.options.uitvoeringtype = value;
    }

    @Input() set prijsm2(value: number) {
        this.options.prijsm2 = parseNumber(value, null);
    }

    @Input() set prijspak(value: number) {
        this.options.prijspak = parseNumber(value, null);
    }

    @Input() set max(value: number) {
        this.options.max = parseNumber(value, null);
    }

    @Input() set disabled(value: boolean) {
        this.options.disabled = !!value;
    }

    @Input() set readonly(value: boolean) {
        this.options.readonly = !!value;
    }

    @Output() valueChange = new EventEmitter();
    // @ViewChild('inputElement', { static: true }) inputElementRef: ElementRef;   //!!

    @ViewChild('aantalm2', { static: true }) aantalm2: ElementRef; //!!

    @ViewChild('aantalpak', { static: true }) aantalpak: ElementRef; //!!

    @ViewChild('prijspak', { static: true }) pakprijs: ElementRef; //!!

    @ViewChild('totaalprijs', { static: true }) totaalprijs: ElementRef; //!!

    @ViewChild('inputElement', { static: true }) inputElementRef: ElementRef;

    get inputElement(): HTMLInputElement {
        //return this.inputElementRef.nativeElement;
        return this.aantalm2.nativeElement;
    }

    get value(): '' | number {
        //  return this.inputElement.value === '' ? '' : parseFloat(this.inputElement.value);
        //  return this.aantalm2.nativeElement.value === '' ? '' : parseFloat(this.aantalm2.nativeElement.value);
        return;
    }

    set value(value: '' | number) {
        this.writeValue(value);
        //this.writeValue(value);
        //this.aantalm2.nativeElement.value = value;
        //this.aantalpak.nativeElement.value = "9"; //!!
    }

    ngOnInit() {
        if (this.options.uitvoeringtype === 'pak') {
            this.text1 = '<strong>Aantal m<sup>2</sup></strong>';
            this.text2 = '<strong>Pakken</strong>';
            this.text3 = '<strong>Totaalprijs</strong>';
        }
        if (this.options.uitvoeringtype === 'rol') {
            this.text1 = '<strong>Aantal m<sup>2</sup></strong>';
            this.text2 = '<strong>Rollen</strong>';
            this.text3 = '<strong>Totaalprijs</strong>';
        }
        this.totaalprijs.nativeElement.innerHTML =
            '&euro; ' + this.options.prijspak.toFixed(2);
    }

    onChange = (_: any) => {};
    onTouched = () => {};

    constructor() {}

    addm2(): void {
        this.changem2(1);
        // this.changeByTimer(1);
    }

    subm2(): void {
        this.changem2(-1);
        //   this.changeByTimer(-1);
    }

    addpak(): void {
        this.changepak(1);
        this.changeByTimer(1);
    }

    subpak(): void {
        this.changepak(-1);
        this.changeByTimer(-1);
    }

    inputm2(): void {
        this.changem2(0);
        console.log(this.aantalm2.nativeElement.value);
        //this.onChange(this.value);
    }

    inputpak(): void {
        this.changepak(0);
        //this.onChange(this.value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    changeValue() {
        // You can give any function name

        this.valueChange.emit(this.aantalpak.nativeElement.value);
    }

    // writeValue(obj: any): void {
    //     if (typeof obj === 'number') {
    //         this.aantalm2.nativeElement.value = obj.toString();
    //     } else {
    //         this.aantalm2.nativeElement.value = '';
    //     }
    // }

    writeValue(obj: any): void {
        if (typeof obj === 'number') {
            this.aantalpak.nativeElement.value = obj.toString();
            this.inputElement.value = obj.toString();
        } else {
            this.aantalpak.nativeElement.value = '';
        }
    }

    /**
     * @param direction - one of [-1, 1]
     */
    private changem2(direction: number): void {
        let value =
            this.aantalm2.nativeElement.value === '' ||
            isNaN(this.aantalm2.nativeElement.value)
                ? 0
                : parseFloat(this.aantalm2.nativeElement.value) +
                  this.options.m2 * direction;
        let value2 =
            this.aantalm2.nativeElement.value === '' ||
            isNaN(this.aantalm2.nativeElement.value)
                ? 0
                : Math.ceil(value / this.options.m2);
        if (direction == 0) {
            value = value2 * this.options.m2;
        }

        if (this.options.max !== null) {
            value = Math.min(this.options.max, value);
        }

        if (this.options.min !== null) {
            value = Math.max(this.options.m2, value);
            value2 = Math.max(this.options.min, value2);
        }

        if (value !== this.value) {
            console.log(value + ' ' + this.value + ' ' + direction);
            if (direction != 0) {
                this.aantalm2.nativeElement.value = value.toFixed(2);
            }
            this.aantalpak.nativeElement.value = value2;
            this.totaalprijs.nativeElement.innerHTML =
                '&euro; ' + (value2 * this.options.prijspak).toFixed(2);

            this.changeValue();
        }
    }

    /**
     * @param direction - one of [-1, 1]
     */
    private changepak(direction: number): void {
        // console.log(this.options.m2);

        let value =
            (this.aantalpak.nativeElement.value === '' ||
            isNaN(this.aantalpak.nativeElement.value)
                ? 0
                : parseInt(this.aantalpak.nativeElement.value)) +
            this.options.step * direction;
        let value2 =
            this.aantalm2.nativeElement.value === '' ||
            isNaN(this.aantalm2.nativeElement.value)
                ? 0
                : this.options.m2 * value;

        if (this.options.max !== null) {
            value = Math.min(this.options.max, value);
        }
        if (this.options.min !== null) {
            value = Math.max(this.options.min, value);
            value2 = Math.max(this.options.m2, value2);
        }

        if (value !== this.value) {
            this.aantalpak.nativeElement.value = value;
            this.aantalm2.nativeElement.value = value2.toFixed(2);
            this.totaalprijs.nativeElement.innerHTML =
                '&euro; ' + (this.options.prijspak * value).toFixed(2);
            //this.writeValue(value);
            this.changeValue();
        }
    }

    /**
     * @param direction - one of [-1, 1]
     */
    private changeByTimer(direction: number): void {
        let interval;
        const timer = setTimeout(() => {
            interval = setInterval(() => this.changem2(direction), 50);
        }, 300);

        const documentMouseUp = () => {
            clearTimeout(timer);
            clearInterval(interval);

            document.removeEventListener('mouseup', documentMouseUp, false);
        };

        document.addEventListener('mouseup', documentMouseUp, false);
    }

    ngOnChanges(changes: SimpleChanges) {
        /*
        console.warn("LALA" + changes.receivedParentMessage.currentValue );
        this.aantalm2.nativeElement.value = changes.receivedParentMessage.currentValue;
        this.changem2(1);


*/
        this.aantalpak.nativeElement.value =
            changes.receivedParentMessage.currentValue - 1;
        //console.log(this.aantalpak.nativeElement.value);
        this.changepak(1);
        // You can also use categoryId.previousValue and
        // categoryId.firstChange for comparing old and new values
    }
}
