<div style="float: right; width: 300px; margin: 8px 0px 5px 15px;" class="d-none d-lg-block"
    *ngIf="!isCollapsed && this.youtubeUrl">
    <iframe style="width: 100%;  display:block;" [src]="this.youtubeUrl" frameborder="0" allowfullscreen></iframe>
    <span
        style="font-size: 16px; float: left; font-weight: bold; background-color: #5e5351; width: 100%; padding: 3px 0px 3px 5px; color: #fff; ">
        <svg style="fill: #fff; float: left;" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
            viewBox="0 0 24 24">
            <path
                d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
        </svg>
        &nbsp;&nbsp;YouTube video</span>
</div>

<a routerLink="/{{this.merkslug}}/{{this.merk}}" *ngIf="isCollapsed && this.merk && !isProductPage && this.merkslug">
<img class="d-none d-xl-block img-fluid" style="float: right; padding-left: 5px;" src="/assets/images/logos/merk-{{this.merk}}.jpg" alt="Logo {{this.merk}}">
</a>

<div [innerHTML]="text" (click)="getRoute($event)" [ngClass]="{'limitTextHeight': isCollapsed}"></div>



<div style="float: right; width: 100%;" class="d-lg-none" *ngIf="!isCollapsed && this.youtubeUrl">
    <br>
    <iframe style="width: 100%;  display:block;" [src]="this.youtubeUrl" frameborder="0" allowfullscreen></iframe>
    <span
        style="font-size: 16px; float: left; font-weight: bold; background-color: #5e5351; width: 100%; padding: 3px 0px 3px 5px; color: #fff; ">
        <svg style="fill: #fff; float: left;" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
            viewBox="0 0 24 24">
            <path
                d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
        </svg>
        &nbsp;&nbsp;YouTube video</span>
    <br><br>
</div>

<div [ngClass]="{'d-md-none': isProductPage}">
    <span style="float: left; padding: 5px 8px 0px 0px; fill: #0090e3;">

        <svg width="9px" viewBox="0 0 63 99" aria-hidden="true" focusable="false">
            <path
                d="M59.8 57.6l-38 38c-10.7 10.8-29.4-7-18.3-18l28-28L3.5 21C-8 9.5 12.2-6.5 21.7 3l38 38c4.4 5 4.4 12.3 0 16.6z">
            </path>
        </svg>

    </span><a style="padding-top: 1px; display: block" href="javascript:;" (click)="toggleText()">{{ isCollapsed ?
        expandText : collapseText }}</a>
</div>
