import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

interface CompareData {
    items: Product[];
}

@Injectable({
    providedIn: 'root'
})
export class CompareService implements OnDestroy {
    private data: CompareData = {
        items: []
    };

    private destroy$: Subject<void> = new Subject();
    private itemsSubject$: BehaviorSubject<Product[]> = new BehaviorSubject([]);
    private onAddingSubject$: Subject<Product> = new Subject();

    readonly items$: Observable<Product[]> = this.itemsSubject$.pipe(
        takeUntil(this.destroy$)
    );
    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
        }
    }

    anyOnCompareList(): boolean {
        const index = this.data.items.length;
        if (index > 0) {
            return true;
        } else {
            return false;
        }
    }

    onCompareList(product: Product): boolean {
        if (this.data.items.length > 0) {
            const index = this.data.items.findIndex(
                (item) => item.id === product.id
            );
            if (index !== -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    add(product: Product): Observable<void> {
        // timer only for demo
        return timer(0).pipe(
            map(() => {
                this.onAddingSubject$.next(product);

                const index = this.data.items.findIndex(
                    (item) => item.id === product.id
                );

                if (index === -1) {
                    this.data.items.push(product);
                    this.save();
                }
            })
        );
    }

    remove(product: Product): Observable<void> {
        // timer only for demo
        return timer(0).pipe(
            map(() => {
                const index = this.data.items.findIndex(
                    (item) => item.id === product.id
                );

                if (index !== -1) {
                    this.data.items.splice(index, 1);
                    this.save();
                }
            })
        );
    }

    private save(): void {
        localStorage.setItem('compareItems', JSON.stringify(this.data.items));

        this.itemsSubject$.next(this.data.items);
    }

    private load(): void {
        const items = localStorage.getItem('compareItems');

        if (items) {
            this.data.items = JSON.parse(items);
            this.itemsSubject$.next(this.data.items);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
