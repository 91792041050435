import { Component, Input, AfterViewInit, HostBinding } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-collapsable-text',
    standalone: true,
    imports: [CommonModule, RouterLink],
    templateUrl: './collapsable-text.component.html',
    styleUrls: ['./collapsable-text.component.scss']
})
export class CollapsableTextComponent implements AfterViewInit {
    @Input() collapseId = 'iscollapsed'; // change this when using the component multiple times on the same page.
    @Input() text: string;
    @Input() expandText = 'Bekijk volledige omschrijving';
    @Input() collapseText = 'Maak omschrijving korter';
    @Input() youtubeUrl: string;
    @Input() merk: string;
    @Input() merkslug: string;

    @HostBinding('class.is-product-page')
    @Input()
    isProductPage = false;

    isCollapsed = true;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location
    ) {}

    getRoute(event) {
        const hrefAttribute = event.target.getAttribute('href');
        if (hrefAttribute && !hrefAttribute.includes('http')) {
            event.preventDefault();
            this.router.navigate([event.target.getAttribute('href')]);
        }
    }

    toggleText() {
        this.isCollapsed = !this.isCollapsed;
        this.setCollapsedToQuery();
    }

    ngAfterViewInit() {
        Promise.resolve().then(() => this.readCollapsedFromQuery());
    }

    private readCollapsedFromQuery() {
        const urlValue = this.activatedRoute.snapshot.queryParamMap.get(
            this.collapseId
        );
        this.isCollapsed = !urlValue || urlValue === 'true';
    }

    private setCollapsedToQuery() {
        const tree = this.router.parseUrl(this.router.url);
        tree.queryParams = { [this.collapseId]: this.isCollapsed };
        this.location.replaceState(tree.toString());
    }
}
