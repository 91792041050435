import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class SeoService {
    constructor(@Inject(DOCUMENT) private doc, @Inject(DOCUMENT) private dom) {}

    createLinkForCanonicalURL(slug) {
        //if (slug.includes('https')) {
        const element: HTMLLinkElement =
            this.dom.querySelector(`link[rel='canonical']`) || null;
        if (element == null) {
            const link: HTMLLinkElement = this.doc.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.doc.head.appendChild(link);
            link.setAttribute('href', slug);
        }
        //}
    }

    createLinkForPrevLink(slug) {
        const element: HTMLLinkElement =
            this.dom.querySelector(`link[rel='prev']`) || null;
        if (element == null) {
            const link: HTMLLinkElement = this.doc.createElement('link');
            link.setAttribute('rel', 'prev');
            this.doc.head.appendChild(link);
            link.setAttribute('href', slug);
        }
    }

    createLinkForNextLink(slug) {
        const element: HTMLLinkElement =
            this.dom.querySelector(`link[rel='next']`) || null;
        if (element == null) {
            const link: HTMLLinkElement = this.doc.createElement('link');
            link.setAttribute('rel', 'next');
            this.doc.head.appendChild(link);
            link.setAttribute('href', slug);
        }
    }
}
