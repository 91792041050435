import {
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    QueryList,
    ViewChild,
    ViewChildren,
    TemplateRef,
    Output,
    EventEmitter
} from '@angular/core';
import { Product } from '../../interfaces/product';
import { CarouselComponent, SlidesOutputData } from 'ngx-owl-carousel-o';
import { UntypedFormControl } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { isPlatformBrowser } from '@angular/common';
import { OwlCarouselOConfig } from 'ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config';
import { PhotoSwipeService } from '../../services/photo-swipe.service';
//import { DirectionService } from '../../services/direction.service';
import { QuickviewService } from '../../services/quickview.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FBCReviewsService } from '../../services/fbc-reviews.service';
import { fbcReviews } from '../../interfaces/fbc-reviews';

import { CartModalService } from '../../services/cartmodal.service';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CustomGlobalConstants } from '../../../shared/constants/constants';
import { LowerCasePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FakeSlidesDirective } from '../../directives/fake-slides.directive';
import { GTMService } from '../../services/gtm.service';

//import { Observable, tap } from 'rxjs';
//import { tap } from 'rxjs/operators';

interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}

@Component({
    selector: 'app-ondervloeren',
    standalone: true,
    imports: [
        LowerCasePipe,
        RouterLink,
        CommonModule,
        IconComponent,
        CurrencyFormatPipe,
        FormsModule,
        CarouselModule,
        FakeSlidesDirective,
        ReactiveFormsModule
    ],
    templateUrl: './ondervloeren.component.html',
    styleUrls: ['./ondervloeren.component.scss']
})
export class OndervloerenComponent implements OnInit {
    options = {
        step: 1,
        min: null,
        max: null,
        disabled: false,
        readonly: false,
        m2: null,
        prijsm2: null,
        prijspak: null,
        uitvoeringtype: null
    };

    current = 1;
    quantity: UntypedFormControl = new UntypedFormControl(1);

    private dataProduct: Product;
    modalRef: BsModalRef;

    isCollapsed = false;
    showGallery = true;
    showGalleryTimeout: number;
    showingQuickview = false;
    images: ProductImage[] = [];

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;

    onCompareList = false;
    anyOnCompareList = false;
    showMore = false;

    receivedChildMessage: string;
    messageToSendP = 0;
    subscription: Subscription;
    message: string;
    aantalpak = 1;
    jsonLD: SafeHtml;
    fbcreviews: fbcReviews;
    blackfridayactie: boolean;
    iDealKortingPercentage: number;

    isReadMore = true;
    obj: any;

    get value(): '' | number {
        return;
    }

    set value(value: '' | number) {
        this.writeValue(value);
    }

    onTouched = () => {};

    carouselOptions: Partial<OwlCarouselOConfig> = {
        dots: false,
        autoplay: false,
        responsive: {
            0: { items: 1 }
        },
        rtl: false
        //rtl: this.direction.isRTL()
    };

    thumbnailsCarouselOptions: Partial<OwlCarouselOConfig> = {
        dots: false,
        autoplay: false,
        margin: 10,
        items: 5,
        responsive: {
            480: { items: 5 },
            380: { items: 4 },
            0: { items: 3 }
        },
        rtl: false
        //rtl: this.direction.isRTL()
    };

    carouselDefaultOptions2: any = {
        items: 4,
        margin: 14,
        nav: false,
        dots: false,
        loop: true,
        stagePadding: 1,
        autoWidth: true,
        rtl: false
        //rtl: this.direction.isRTL()
    };

    lastViewedArray = [];

    @ViewChild('featuredCarousel', { read: CarouselComponent })
    featuredCarousel: CarouselComponent;
    @ViewChild('thumbnailsCarousel', { read: CarouselComponent })
    thumbnailsCarousel: CarouselComponent;
    @ViewChildren('imageElement', { read: ElementRef })
    imageElements: QueryList<ElementRef>;

    @Output() messageToEmit = new EventEmitter<string>();

    @Input() set product(value: Product) {
        this.dataProduct = value;
        this.images = value
            ? this.dataProduct.images.map((url, index) => {
                  return {
                      id: index.toString(),
                      url,
                      active: index === 0
                  };
              })
            : [];
    }
    get product(): Product {
        // console.log(JSON.stringify(this.dataProduct));
        return this.dataProduct;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        private photoSwipe: PhotoSwipeService,
        //private direction: DirectionService,
        public quickview: QuickviewService,
        public cartmodal: CartModalService,
        private data: CartModalService,
        private modalService: BsModalService,
        private titleService: Title,
        private metaService: Meta,
        private httpService: FBCReviewsService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private httpClient: HttpClient,
        private route: ActivatedRoute, //private analytics: AngularFireAnalytics
        private _dataLayerService: GTMService
    ) {
        this.blackfridayactie = CustomGlobalConstants.BlackFridayActie;
        this.iDealKortingPercentage = CustomGlobalConstants.iDealKorting;
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.httpClient
                .get(
                    'https://api.voordeelvloeren.nl/zelfde-serie.php?id=' +
                        params['productSlug'],
                    {
                        responseType: 'text'
                    }
                )
                .subscribe((response) => {
                    this.obj = JSON.parse(response);
                });
        });

        // if (this.product.categorieid == 3) {
        //     this.titleService.setTitle(
        //         this.product.name + ' ondervloer kopen - Voordeelvloeren'
        //     );
        // }

        // if (this.product.categorieid == 4) {
        //     this.titleService.setTitle(
        //         this.product.name + ' kopen - Voordeelvloeren'
        //     );
        // }

        this.httpService
            .getFBCReviews()

            .subscribe((res) => {
                this.fbcreviews = res;

                const json = {
                    '@context': 'http://schema.org/',
                    '@type': 'Product',
                    name: this.product.name,
                    image: this.product.images[0],
                    url:
                        'https://www.voordeelvloeren.nl/product/' +
                        this.product.id +
                        '/' +
                        this.product.slug,
                    description: this.product.metadescription,
                    sku: this.product.ean,
                    id:
                        'https://www.voordeelvloeren.nl/product/' +
                        this.product.id +
                        '/' +
                        this.product.slug,
                    brand: {
                        '@type': 'Brand',
                        name: this.product.merknaam
                    },

                    offers: {
                        '@type': 'Offer',
                        priceCurrency: 'EUR',
                        price: this.product.prijsm2,
                        url:
                            'https://www.voordeelvloeren.nl/product/' +
                            this.product.id +
                            '/' +
                            this.product.slug,
                        itemCondition: 'https://schema.org/NewCondition',
                        availability: 'http://schema.org/InStock'
                    },
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: this.fbcreviews['score'].toString(),
                        reviewCount: this.fbcreviews['count'].toString(),
                        bestRating: '10',
                        worstRating: '1'
                    }
                };

                this.jsonLD = this.getSafeHTML(json);
            });
        // this.metaService.updateTag({
        //     name: 'description',
        //     content:
        //         'Handige tips en uitleg om een complete laminaatvloer te kopen. Alles draait om de juiste ondervloer waarop het laminaat gelegd wordt. Advies over het maken van de juiste keuze afwerking met muurplinten, plakplinten en rozetten. Alle toebehoren zijn op elkaar afgestemd voor een perfect eindresultaat.'
        // });

        this.subscription = this.data.currentMessageCM.subscribe(
            (message) => (this.message = message)
        );

        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('lastViewed')) {
                this.lastViewedArray = JSON.parse(
                    localStorage.getItem('lastViewed')
                );
            }
        }

        if (this.lastViewedArray.length > 0) {
            if (this.lastViewedArray.length == 10) {
                this.lastViewedArray.pop();
            }

            this.lastViewedArray.forEach((element, index) => {
                if (element == this.product.id)
                    this.lastViewedArray.splice(index, 1);
            });

            this.lastViewedArray.unshift(this.product.id);

            if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem(
                    'lastViewed',
                    JSON.stringify(this.lastViewedArray)
                );
            }
        } else {
            this.lastViewedArray = [this.product.id];
            if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem(
                    'lastViewed',
                    JSON.stringify(this.lastViewedArray)
                );
            }
        }

        // this.analytics.logEvent('view_item', {
        //     currency: 'EUR',
        //     value: this.product.prijsm2,
        //     items: [
        //         {
        //             item_id: 'vdvl-' + this.product.id,
        //             item_name: this.product.name,
        //             currency: 'EUR',
        //             item_brand: this.product.merknaam,
        //             item_category: this.product.categorienaam,
        //             price: this.product.prijsm2,
        //             quantity: 1
        //         }
        //     ]
        // });

        if (isPlatformBrowser(this.platformId)) {
            this._dataLayerService.logEvent('view_item', this.product.prijsm2, [
                {
                    item_id: 'vdvl-' + this.product.id,
                    item_name: this.product.name,
                    currency: 'EUR',
                    item_brand: this.product.merknaam,
                    item_category: this.product.categorienaam,
                    price: this.product.prijsm2,
                    quantity: 1
                }
            ]);
        }
    }

    addToWishlist(): void {
        if (!this.addingToWishlist && this.product) {
            this.addingToWishlist = true;

            this.wishlist
                .add(this.product)
                .subscribe({ complete: () => (this.addingToWishlist = false) });
        }
    }

    setActiveImage(image: ProductImage): void {
        this.images.forEach(
            (eachImage) => (eachImage.active = eachImage === image)
        );
    }

    toggleCompare(): void {
        //  this.onCompareList = this.compare.onCompareList(this.product);
        //      console.log("Z1");
        console.log(this.addingToCompare);
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            if (this.compare.onCompareList(this.product) === false) {
                this.compare.add(this.product).subscribe({
                    complete: () => (this.addingToCompare = false)
                });
                this.addingToCompare = true;
            } else {
                this.compare.remove(this.product).subscribe({
                    complete: () => (this.addingToCompare = false)
                });
                this.addingToCompare = false;
            }
        }
    }

    featuredCarouselTranslated(event: SlidesOutputData): void {
        if (event.slides.length) {
            const activeImageId = event.slides[0].id;

            this.images.forEach(
                (eachImage) =>
                    (eachImage.active = eachImage.id === activeImageId)
            );

            if (
                !this.thumbnailsCarousel.slidesData.find(
                    (slide) => slide.id === event.slides[0].id && slide.isActive
                )
            ) {
                this.thumbnailsCarousel.to(event.slides[0].id);
            }
        }
    }

    openPhotoSwipe(event: MouseEvent, image: ProductImage): void {
        event.preventDefault();

        const images = this.images.map((eachImage) => {
            return {
                src: eachImage.url,
                msrc: eachImage.url,
                w: 700,
                h: 700
            };
        });

        // if (this.direction.isRTL()) {
        //     images.reverse();
        // }

        const options = {
            getThumbBoundsFn: (index) => {
                const imageElements = this.imageElements.toArray();
                const dirDependentIndex = this.getIndexDependOnDir(index);

                if (!imageElements[dirDependentIndex]) {
                    return null;
                }

                const imageElement =
                    imageElements[dirDependentIndex].nativeElement;
                const pageYScroll =
                    window.pageYOffset || document.documentElement.scrollTop;
                const rect = imageElement.getBoundingClientRect();

                return {
                    x: rect.left,
                    y: rect.top + pageYScroll,
                    w: rect.width
                };
            },
            index: this.getIndexDependOnDir(this.images.indexOf(image)),
            bgOpacity: 0.9,
            history: false
        };

        this.photoSwipe.open(images, options).subscribe((galleryRef) => {
            galleryRef.listen('beforeChange', () => {
                this.featuredCarousel.to(
                    this.images[
                        this.getIndexDependOnDir(galleryRef.getCurrentIndex())
                    ].id
                );
            });
        });
    }

    getIndexDependOnDir(index) {
        // we need to invert index id direction === 'rtl' because photoswipe do not support rtl
        // if (this.direction.isRTL()) {
        //     return this.images.length - 1 - index;
        // }

        return index;
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare
                .add(this.product)
                .subscribe({ complete: () => (this.addingToCompare = false) });
        }
    }

    getMessage(message: string) {
        this.receivedChildMessage = message;
        // this.product.m2 = message;
        this.sendToChild(message);
    }

    sendToChild(message: string) {
        //this.messageToSendP = message;
    }

    addToCart(): void {
        if (!this.addingToCart && this.product && this.quantity.value > 0) {
            if (isPlatformBrowser(this.platformId)) {
                this._dataLayerService.logEvent(
                    'add_to_cart',
                    this.product.prijspak * this.aantalpak,
                    [
                        {
                            item_id: 'vdvl-' + this.product.id,
                            item_name: this.product.name,
                            currency: 'EUR',
                            item_brand: this.product.merknaam,
                            item_category: this.product.categorienaam,
                            price: this.product.prijsm2,
                            quantity: this.aantalpak
                        }
                    ]
                );
            }

            // this.analytics.logEvent('add_to_cart', {
            //     currency: 'EUR',
            //     value: this.product.prijspak * this.aantalpak,
            //     items: [
            //         {
            //             item_id: 'vdvl-' + this.product.id,
            //             item_name: this.product.name,
            //             currency: 'EUR',
            //             item_brand: this.product.merknaam,
            //             item_category: this.product.categorienaam,
            //             price: this.product.prijspak,
            //             quantity: this.aantalpak
            //         }
            //     ]
            // });

            this.addingToCart = true;

            // stuur data naar (addedTo)CartModal
            this.data.toModalCart(this.aantalpak + '-0-0');

            this.cart
                .add(this.product, this.aantalpak, 0, 0, false)
                .subscribe({ complete: () => (this.addingToCart = false) });
        }
    }

    changeValues(): void {
        //this.valueChange.emit(this.current);
        this.messageToSendP = this.current;
        this.aantalpak = this.current;
    }

    addpak(): void {
        this.changepak(1);
        //   this.changeByTimer(1);
    }

    subpak(): void {
        this.changepak(-1);
        // this.changeByTimer(-1);
    }

    inputpak(): void {
        this.changepak(0);
        //this.onChange(this.value);
    }

    writeValue(obj: any): void {
        if (typeof obj === 'number') {
            // this.aantalpakken.nativeElement.value = obj.toString();
        } else {
            //  this.aantalpakken.nativeElement.value = '';
        }
    }

    private changepak(direction: number): void {
        // console.log(this.options.m2);

        const value =
            (!this.current || isNaN(this.current) ? 0 : this.current) +
            this.options.step * direction;

        // let value =
        //     (this.aantalpakken.nativeElement.value === '' ||
        //     isNaN(this.aantalpakken.nativeElement.value)
        //         ? 0
        //         : parseInt(this.aantalpakken.nativeElement.value)) +
        //     this.options.step * direction;

        // if (this.options.max !== null) {
        //     value = Math.min(this.options.max, value);
        // }

        //console.log(value);

        if (value !== this.value) {
            this.current = value;

            this.changeValues();
        }
    }

    getSafeHTML(value: {}) {
        const json = JSON.stringify(value, null, 2);
        const html = `<script type="application/ld+json">${json}</script>`;
        // Inject to inner html without Angular stripping out content
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    showText() {
        this.isReadMore = !this.isReadMore;
    }

    getRoute(event) {
        if (!event.target.getAttribute('href').includes('http')) {
            event.preventDefault();
            this.router.navigate([event.target.getAttribute('href')]);
        }
    }
}
