import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GeneralModalService {
    private messageSource = new BehaviorSubject('0-0-0');
    currentMessage = this.messageSource.asObservable();

    constructor() {}

    toModalCart(message: string) {
        this.messageSource.next(message);
    }
}
